const data = [
    { name: 'Chipped amethyst', weapon: '+40 to Attack Rating', armour: '+3 to Strength', shield: '+8 to Defense', recipe: '', type: 'gem'},
    { name: 'Flawed amethyst', weapon: '+60 to Attack Rating', armour: '+4 to Strength', shield: '+12 to Defense', recipe: '3-Chipped amethyst', type: 'gem'},
    { name: 'Amethyst', weapon: '+80 to Attack Rating', armour: '+6 to Strength', shield: '+18 to Defense', recipe: '3-Flawed amethyst', type: 'gem'},
    { name: 'Flawless amethyst', weapon: '+100 to Attack Rating', armour: '+8 to Strength', shield: '+24 to Defense', recipe: '3-Amethyst', type: 'gem'},
    { name: 'Perfect amethyst', weapon: '+150 to Attack Rating', armour: '+10 to Strength', shield: '+30 to Defense', recipe: '3-Flawless amethyst', type: 'gem'},
    { name: 'Chipped diamond', weapon: '+28% damage vs. the undead', armour: '+20 to Attack Rating', shield: '+6% Resistance to All', recipe: '', type: 'gem'},
    { name: 'Flawed diamond', weapon: '+34% damage vs. the undead', armour: '+40 to Attack Rating', shield: '+8% Resistance to All', recipe: '3-Chipped diamond', type: 'gem'},
    { name: 'Diamond', weapon: '+44% damage vs. the undead', armour: '+60 to Attack Rating', shield: '+11% Resistance to All', recipe: '3-Flawed diamond', type: 'gem'},
    { name: 'Flawless diamond', weapon: '+54% damage vs. the undead', armour: '+80 to Attack Rating', shield: '+14% Resistance to All', recipe: '3-Diamond', type: 'gem'},
    { name: 'Perfect diamond', weapon: '+68% damage vs. the undead', armour: '+100 to Attack Rating', shield: '+19% Resistance to All', recipe: '3-Flawless diamond', type: 'gem'},
    { name: 'Chipped emerald', weapon: 'Adds 5 (5.9) poison damage in 1 sec', armour: '+3 to Dexterity', shield: '+12% Poison Resistance', recipe: '', type: 'gem'},
    { name: 'Flawed emerald', weapon: 'Adds 11 (11.7) poison damage in 1 sec', armour: '+4 to Dexterity', shield: '+16% Poison Resistance', recipe: '3-Chipped emerald', type: 'gem'},
    { name: 'Emerald', weapon: 'Adds 17 (17.6) poison damage in 1 sec', armour: '+6 to Dexterity', shield: '+22% Poison Resistance', recipe: '3-Flawed emerald', type: 'gem'},
    { name: 'Flawless emerald', weapon: 'Adds 23 (23.4) poison damage in 1 sec', armour: '+8 to Dexterity', shield: '+28% Poison Resistance', recipe: '3-Emerald', type: 'gem'},
    { name: 'Perfect emerald', weapon: 'Adds 29 (29.3) poison damage in 1 sec', armour: '+10 to Dexterity', shield: '+40% Poison Resistance', recipe: '3-Flawless emerald', type: 'gem'},
    { name: 'Chipped ruby', weapon: '+3-4 Fire Damage', armour: '+10 to Maximum Life', shield: '+12% fire resistance', recipe: '', type: 'gem'},
    { name: 'Flawed ruby', weapon: '+5-8 to Fire Damage', armour: '+17 to Maximum Life', shield: '+16% to Fire Resistance', recipe: '3-Chipped ruby', type: 'gem'},
    { name: 'Ruby', weapon: '+8-12 to Fire Damage', armour: '+24 to Maximum Life', shield: '+22% fire resistance', recipe: '3-Flawed ruby', type: 'gem'},
    { name: 'Flawless ruby', weapon: '+10-16 to Fire Damage', armour: '+31 to Maximum Life', shield: '+28% fire resistance', recipe: '3-Ruby', type: 'gem'},
    { name: 'Perfect ruby', weapon: '+15-20 Fire Damage', armour: '+38 to Maximum Life', shield: '+40% fire resistance', recipe: '3-Flawless ruby', type: 'gem'},
    { name: 'Chipped sapphire', weapon: '+1-3 Cold Damage 1 second Cold Time', armour: '+10 to Maximum Mana', shield: '+12% to Cold Resistance', recipe: '', type: 'gem'},
    { name: 'Flawed sapphire', weapon: '+3-5 Cold Damage 1.4 second Cold Time', armour: '+17 to Maximum Mana', shield: '+16% to Cold Resistance', recipe: '3-Chipped sapphire', type: 'gem'},
    { name: 'Sapphire', weapon: '+4-7 Cold Damage 2 second Cold Time', armour: '+24 to Maximum Mana', shield: '+22% to Cold Resistance', recipe: '3-Flawed sapphire', type: 'gem'},
    { name: 'Flawless sapphire', weapon: '+6-10 Cold Damage 2.4 second Cold Time', armour: '+31 to Maximum Mana', shield: '+28% to Cold Resistance', recipe: '3-Sapphire', type: 'gem'},
    { name: 'Perfect sapphire', weapon: '+10-14 Cold Damage 3 second Cold Time', armour: '+38 to Maximum Mana', shield: '+40% to Cold Resistance', recipe: '3-Flawless sapphire', type: 'gem'},
    { name: 'Chipped topaz', weapon: '+1-8 Lightning Damage', armour: '+9% chance to find magical items', shield: '+12% Lightning Resistance', recipe: '', type: 'gem'},
    { name: 'Flawed topaz', weapon: '+1-14 Lightning Damage', armour: '+13% chance to find magical items', shield: '+16% Lightning Resistance', recipe: '3-Chipped topaz', type: 'gem'},
    { name: 'Topaz', weapon: '+1-22 Lightning Damage', armour: '+16% chance to find magical items', shield: '+22% Lightning Resistance', recipe: '3-Flawed topaz', type: 'gem'},
    { name: 'Flawless topaz', weapon: '+1-30 Lightning Damage', armour: '+20% chance to find magical items', shield: '+28% Lightning Resistance', recipe: '3-Topaz', type: 'gem'},
    { name: 'Perfect topaz', weapon: '+1-40 Lightning Damage', armour: '+24% chance to find magical items', shield: '+40% Lightning Resistance', recipe: '3-Flawless topaz', type: 'gem'},
    { name: 'Chipped skull', weapon: 'Steals 2% life and 1% mana per hit', armour: 'Replenish life +2 and mana regen. 8%', shield: 'Attacker takes damage of 4', recipe: '', type: 'gem'},
    { name: 'Flawed skull', weapon: 'Steals 2% life and 2% mana per hit', armour: 'Replenish life +3 and mana regen. 8%', shield: 'Attacker takes damage of 8', recipe: '3-Chipped skull', type: 'gem'},
    { name: 'Skull', weapon: 'Steals 3% life and 2% mana per hit', armour: 'Replenish life +3 and mana regen. 12%', shield: 'Attacker takes damage of 12', recipe: '3-Flawed skull', type: 'gem'},
    { name: 'Flawless skull', weapon: 'Steals 3% life and 3% mana per hit', armour: 'Replenish life +4 and mana regen. 12%', shield: 'Attacker takes damage of 16', recipe: '3-Skull', type: 'gem'},
    { name: 'Perfect skull', weapon: 'Steals 4% life and 3% mana per hit', armour: 'Replenish life +5 and mana regen 19%', shield: 'Attacker takes damage of 20', recipe: '3-Flawless skull', type: 'gem'},
    { name: 'El', weapon: '+50 attack rating, +1 light radius', armour: '+15 defense, +1 light radius', recipe: '',type: 'rune', level: '11'},
    { name: 'Eld', weapon: '+75% damage to undead +50 attack rating against undead', armour: '15% slower stamina drain', shield: '+7% chance to block', recipe: '3-El',type: 'rune', level: '11'},
    { name: 'Tir', weapon: '+2 mana after each kill', armour: '+2 mana after each kill', recipe: '3-Eld',type: 'rune', level: '13'},
    { name: 'Nef', weapon: 'Knockback', armour: '+30 missile defense', recipe: '3-Tir',type: 'rune', level: '13'},
    { name: 'Eth', weapon: '-25% target defense', armour: '+15% mana regeneration', recipe: '3-Nef',type: 'rune', level: '15'},
    { name: 'Ith', weapon: '+9 maximum damage', armour: '15% damage taken goes to mana', recipe: '3-Eth',type: 'rune', level: '15'},
    { name: 'Tal', weapon: '+75 poison damage over 5 seconds', armour: '+30% poison resist', shield: '+35% poison resist', recipe: '3-Ith',type: 'rune', level: '17'},
    { name: 'Ral', weapon: 'Add 5-30 fire damage', armour: '+30% fire resist', shield: '+35% fire resist', recipe: '3-Tal',type: 'rune', level: '19'},
    { name: 'Ort', weapon: 'Add 1-50 lightning damage', armour: '+30% lightning resist', shield: '+35% lightning resist', recipe: '3-Ral',type: 'rune', level: '21'},
    { name: 'Thul', weapon: 'Add 3-14 cold damage (3 seconds)', armour: '+30% cold resist', shield: '+35% cold resist', recipe: '3-Ort',type: 'rune', level: '23'},
    { name: 'Amn', weapon: '7% life stolen per hit', armour: 'Attacker takes damage of 14', recipe: '3-Thul, 1-Chipped topaz',type: 'rune', level: '25'},
    { name: 'Sol', weapon: '+9 minimum damage', armour: 'Damage reduced by 7', recipe: '3-Amn, 1-Chipped amethyst',type: 'rune', level: '27'},
    { name: 'Shael', weapon: '20% increased attack speed', armour: '20% faster hit recovery', shield: '20% faster block rate', recipe: '3-Sol, 1-Chipped sapphire',type: 'rune', level: '29'},
    { name: 'Dol', weapon: 'Hit causes monster to flee (25%)', armour: '+7 replenish life', recipe: '3-Shael, 1-Chipped ruby',type: 'rune', level: '31'},
    { name: 'Hel', weapon: '-20% requirements', armour: '-15% requirements', recipe: '3-Dol, 1-Chipped emerald',type: 'rune', level: '33'},
    { name: 'Io', weapon: '+10 vitality', armour: '+10 vitality', recipe: '3-Hel, 1-Chipped diamond',type: 'rune', level: '35'},
    { name: 'Lum', weapon: '+10 energy', armour: '+10 energy', recipe: '3-Io, 1-Flawed topaz',type: 'rune', level: '37'},
    { name: 'Ko', weapon: '+10 dexterity', armour: '+10 dexterity', recipe: '3-Lum, 1-Flawed amethyst',type: 'rune', level: '39'},
    { name: 'Fal', weapon: '+10 strength', armour: '+10 strength', recipe: '3-Ko, 1-Flawed sapphire',type: 'rune', level: '41'},
    { name: 'Lem', weapon: '75% extra gold from monsters', armour: '50% extra gold from monsters', recipe: '3-Fal, 1-Flawed ruby',type: 'rune', level: '43'},
    { name: 'Pul', weapon: '+75% damage to demons +100 attack rating against demons', armour: '+30% enhanced defense', recipe: '3-Lem, 1-Flawed emerald',type: 'rune', level: '45'},
    { name: 'Um', weapon: '25% chance of open wounds', armour: '+15 all resistances', shield: '+22 all resistances', recipe: '2-Pul, 1-Flawed diamond',type: 'rune', level: '47'},
    { name: 'Mal', weapon: 'Prevent monster heal', armour: 'Magic damage reduced by 7', recipe: '2-Um, 1-Topaz',type: 'rune', level: '49'},
    { name: 'Ist', weapon: '+30% better magic find', armour: '+25% better magic find', recipe: '2-Mal, 1-Amethyst',type: 'rune', level: '51'},
    { name: 'Gul', weapon: '+20% bonus to attack rating', armour: '+5% to maximum poison resist', recipe: '2-Ist, 1-Sapphire',type: 'rune', level: '53'},
    { name: 'Vex', weapon: '7% mana stolen per hit', armour: '+5% to maximum fire resist', recipe: '2-Gul, 1-Ruby',type: 'rune', level: '55'},
    { name: 'Ohm', weapon: '+50% enhanced damage', armour: '+5% to maximum cold resist', recipe: '2-Vex, 1-Emerald',type: 'rune', level: '57'},
    { name: 'Lo', weapon: '+20% deadly strike', armour: '+5% to maximum lightning resist', recipe: '2-Ohm, 1-Diamond',type: 'rune', level: '59'},
    { name: 'Sur', weapon: 'Hit blinds target', armour: '+5% maximum mana', shield: '+50 mana', recipe: '2-Lo, 1-Flawless topaz',type: 'rune', level: '61'},
    { name: 'Ber', weapon: '+20% chance of crushing blow', armour: 'Damage reduced by 8%', recipe: '2-Sur, 1-Flawless amethyst',type: 'rune', level: '63'},
    { name: 'Jah', weapon: 'Ignore target\'s defense', armour: '+5% maximum life', shield: '+50 life', recipe: '2-Ber, 1-Flawless sapphire',type: 'rune', level: '65'},
    { name: 'Cham', weapon: 'Freeze target +3', armour: 'Cannot be frozen', recipe: '2-Jah, 1-Flawless ruby',type: 'rune', level: '67'},
    { name: 'Zod', weapon: 'Indestructible', armour: 'Indestructible', recipe: '2-Cham, 1-Flawless emerald',type: 'rune', level: '69'},
  ];

export default data;