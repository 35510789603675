import {createRouter, createWebHistory} from 'vue-router';
import Home from '../components/Home';
import RuneList from '../components/rune-list/RuneList';
import Breakpoints from '../components/breakpoints/Breakpoints';
import Experience from '../components/experience/Experience';

const router = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {path: '/', component: Home, props: true},
        {path: '/runes', component: RuneList, props: true},
        {path: '/breakpoints', component: Breakpoints, props: true},
        {path: '/experience', component: Experience, props: true}
    ]
})

export default router