const data = [
  { name: 'Ancient\'s Pledge', properties: '+50% enhanced defense,Cold resist +43%,Fire resist +48%,Lightning resist +48%,Poison resist +48%,10% damage taken goes to mana', runes: 'Ral,Ort,Tal', applicable: 'shields', type: 'word'},
  { name: 'Beast', properties: 'Level 9 Fanaticism aura when equipped,+40% increased attack speed,+240-270% enhanced damage (varies),20% chance of crushing blow,25% chance of open wounds,+3 to Werebear,+3 to Lycanthropy,Prevent monster heal,+25-40 strength (varies),+10 to energy,+2 to mana after each kill,Level 13 Summon Grizzly (5 charges)', runes: 'Ber,Tir,Um,Mal,Lum', applicable: 'axes, scepters, hammers', type: 'word', patch: '1.10'},
  { name: 'Black', properties: '+120% enhanced damage,40% chance of crushing blow,+200 attack rating,Adds 3-14 cold damage (3 seconds),+10 to vitality,15% increased attack speed,Knockback,Magic damage reduced by 2,Level 4 Corpse Explosion (12 charges)', runes: 'Thul,Io,Nef', applicable: 'clubs, hammers, maces', type: 'word'},
  { name: 'Bone', properties: '15% chance to cast level 10 Bone Armor when struck,15% chance to cast level 10 Bone Spear on striking,+2 to Necromancer skill levels,+100-150 mana (varies),All resistances +30,Damage reduced by 7', runes: 'Sol,Um,Um', applicable: 'body armor', type: 'word', patch: '1.11'},
  { name: 'Bramble', properties: 'Level 15-21 Thorns aura when equipped (varies),+50% faster hit Recovery,+25-50% to poison skill damage (varies),+300 defense,Increase maximum mana 50%,Regenerate mana 15%,+5% maximum cold resist,Fire resist +30%,Poison resist +100%,+13 life after each kill,Level 13 Spirit of Barbs (33 charges)', runes: 'Ral,Ohm,Sur,Eth', applicable: 'body armor', type: 'word', patch: '1.10'},
  { name: 'Brand', properties: '35% chance to cast level 14 Amplify Damage when struck,100% chance to cast level 18 Bone Spear on striking,+260-340% enhanced damage (varies),Ignore target\'s defense,20% bonus to attack rating,+280-330% damage to demons (varies),20% deadly strike,Prevent monster heal,Knockback,Fires explosive arrows or bolts', runes: 'Jah,Lo,Mal,Gul', applicable: 'missile weapons', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Breath of the Dying', properties: '50% chance to cast level 20 Poison Nova when you kill an enemy,Indestructible,+60% increased attack speed,+350-400% enhanced damage (varies),+200% damage to undead,-25% target defense,+50 to attack rating,+50 to attack rating against undead,7% mana stolen per hit,12-15% life stolen per hit (varies),Prevent monster heal,+30 to all attributes,+1 to light radius,Requirements -20%', runes: 'Vex,Hel,El,Eld,Zod,Eth', applicable: 'weapons', type: 'word', patch: '1.10'},
  { name: 'Call to Arms', properties: '+1 to all skills,+40% increased attack speed,+250-290% enhanced damage (varies),Adds 5-30 fire damage,7% life stolen per hit,+2-6 to Battle Command (varies),+1-6 to Battle Orders (varies),+1-4 to Battle Cry (varies),Prevent monster heal,Replenish life +12,30% better chance of getting magic items', runes: 'Amn,Ral,Mal,Ist,Ohm', applicable: 'weapons', type: 'word', patch: '1.10'},
  { name: 'Chains of Honor', properties: '+2 to all skills,+200% damage to demons,+100% damage to undead,8% life stolen per hit,+70% enhanced defense,+20 to strength,Replenish life +7,All resistances +65,Damage reduced by 8%,25% better chance of getting magic items', runes: 'Dol,Um,Ber,Ist', applicable: 'body armor', type: 'word', patch: '1.10'},
  { name: 'Chaos', properties: '9% chance to cast level 11 Frozen Orb on striking,11% chance to cast level 9 Charged Bolt on striking,+35% increased attack speed,+290-340% enhanced damage (varies),Adds 216-471 magic damage,25% chance of open wounds,+1 to Whirlwind,+10 to strength,+15 life after each demon kill', runes: 'Fal,Ohm,Um', applicable: 'claws', type: 'word', patch: '1.10'},
  { name: 'Crescent Moon', properties: '10% chance to cast level 17 Chain Lightning on striking,7% chance to cast level 13 Static Field on striking,+20% increased attack speed,+180-220% enhanced damage (varies),Ignore target\'s defense,-35% to enemy lightning resistance,25% chance of open wounds,+9-11 magic absorb (varies),+2 to mana after each kill,Level 18 Summon Spirit Wolf (30 charges)', runes: 'Shael,Um,Tir', applicable: 'axes, swords, polearms', type: 'word', patch: '1.10'},
  { name: 'Death', properties: '100% chance to cast level 44 Chain Lightning when you die,25% chance to cast level 18 Glacial Spike on attack,Indestructible,+300-385% enhanced damage (varies),20% bonus to attack rating,+50 to attack rating,Adds 1-50 lightning damage,7% mana stolen per hit,50% chance of crushing blow,0.5-49.5% deadly strike (based on character level),+1 to light radius,Level 22 Blood Golem (15 charges),Requirements -20%', runes: 'Hel,El,Vex,Ort,Gul', applicable: 'swords, axes', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Delirium', properties: '1% chance to cast level 50 Delirium when struck,6% chance to cast level 14 Mind Blast when struck,14% chance to cast level 13 Terror when struck,11% chance to cast level 18 Confuse on striking,+2 to all skills,+261 defense,+10 to vitality,50% extra gold from monsters,25% better chance of getting magic items,Level 17 Attract (60 charges)', runes: 'Lem,Ist,Io', applicable: 'helms', type: 'word', patch: '1.10'},
  { name: 'Destruction', properties: '23% chance to cast level 12 Volcano on striking,5% chance to cast level 23 Molten Boulder on striking,100% chance to cast level 45 Meteor when you die,15% chance to cast level 22 Nova on attack,+350% enhanced damage,Ignore target\'s defense,Adds 100-180 magic damage,7% mana stolen per hit,20% chance of crushing blow,20% deadly strike,Prevent monster heal,+10 to dexterity', runes: 'Vex,Lo,Ber,Jah,Ko', applicable: 'polearms, swords', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Doom', properties: '5% chance to cast level 18 Volcano on striking,Level 12 Holy Freeze aura when equipped,+2 to all skills,+45% increased attack speed,+330-370% enhanced damage (varies),-(40-60)% to enemy cold resistance (varies),20% deadly strike,25% chance of open wounds,Prevent monster heal,Freezes target +3,Requirements -20%', runes: 'Hel,Ohm,Um,Lo,Cham', applicable: 'axes, polearms, hammers', type: 'word', patch: '1.10'},
  { name: 'Dragon', properties: '20% chance to cast level 18 Venom when struck,12% chance to cast level 15 Hydra on striking,Level 14 Holy Fire aura when equipped,+360 defense,+230 missile defense,+3-5 to all attributes (varies),0.375-37.125 to strength (based on character level),Increase maximum mana by 5% (body armor),+50 to mana (shields),+5% to maximum lightning resist,Damage reduced by 7', runes: 'Sur,Lo,Sol', applicable: 'body armor, shields', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Dream', properties: '10% chance to cast level 15 Confuse when struck,Level 15 Holy Shock aura when equipped,+20-30% faster hit recovery (varies),+30% enhanced defense,+150-220 defense (varies),+10 to vitality,Increase maximum life 5% (helms),+50 to life (shields),0.625-61.875 to mana (based on character level),All resistance +5-20 (varies),12-25% better chance of getting magic items (varies)', runes: 'Io,Jah,Pul', applicable: 'helms, shields', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Duress', properties: '40% faster hit recover,+10-20% enhanced damage (varies),Adds 37-133 cold damage (2 seconds),15% chance of crushing blow,33% chance of open wounds,+150-200% enhanced defense (varies),-20% slower stamina drain,Cold resist +45%,Lightning resist +15%,Fire resist +15%,Poison resist +15%', runes: 'Shael,Um,Thul', applicable: 'body armor', type: 'word', patch: '1.10'},
  { name: 'Edge', properties: 'Level 15 Thorns aura when equipped,+35% increased attack speed,+320-380% damage to demons (varies),+280% damage to undead,+75 poison damage over 5 seconds,7% life stolen per hit,Prevent monster heal,+5-10 to all attributes (varies),+2 to mana after each kill,Reduces all vendor prices by 15%', runes: 'Tir,Tal,Amn', applicable: 'missile weapons', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Enigma', properties: '+2 to all skills,+45% faster run/walk,+1 to Teleport,+750-775 defense (varies),+0-75 to Strength (based on character level),Increase maximum life by 5%,Damage reduced by 8%,+14 to life after each kill,15% damage taken goes to mana,+1-99% better chance of getting magic items (based on character level)', runes: 'Jah,Ith,Ber', applicable: 'body armor', type: 'word', patch: '1.10'},
  { name: 'Enlightenment', properties: '5% chance to cast level 15 Blaze when struck,5% chance to cast level 15 Fire Ball on striking,+2 to Sorceress skill levels,+1 to Warmth,+30% enhanced defense,Fire resist +30%,Damage reduced by 7', runes: 'Pul,Ral,Sol', applicable: 'body armor', type: 'word', patch: '1.11'},
  { name: 'Eternity', properties: 'Indestructible,+260-310% enhanced damage (varies),+9 to minimum damage,7% life stolen per hit,20% chance of crushing blow,Hit blinds target,Slow target by 33%,Regenerate mana 16%,Replenish life +16,Cannot be frozen,30% better chance of getting magic items,Level 8 Revive (88 charges)', runes: 'Amn,Ber,Ist,Sol,Sur', applicable: 'melee weapons', type: 'word', patch: '1.10'},
  { name: 'Exile', properties: '15% chance to cast level 5 Life Tap on striking,Level 13-16 Defiance aura when equipped (varies),+2 to Paladin Offensive auras,+30% faster block rate,Freezes target,+220-260% enhanced defense (varies),Replenish life +7,+5% maximum cold resist,+5% maximum fire resist,25% better chance of getting magic items,Repairs 1 durability in 4 seconds', runes: 'Vex,Ohm,Ist,Dol', applicable: 'paladin shields', type: 'word', patch: '1.10'},
  { name: 'Faith', properties: 'Level 12-15 Fanaticism aura when equipped (varies),+1-2 all skills (varies),+330% enhanced damage,Ignore target\'s defense,300% bonus to attack rating,+75% damage to undead,+50 to attack rating against undead,+120 fire damage,All resistance +15,10% reanimate as Returned,75% extra gold from monsters', runes: 'Ohm,Jah,Lem,Eld', applicable: 'missile weapons', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Famine', properties: '+30% increased attack speed,+320-370% enhanced damage (varies),Ignore target\'s defense,Adds 180-200 magic damage,Adds 50-200 fire damage,Adds 51-250 lightning damage,Adds 50-200 cold damage,12% life stolen per hit,Prevent monster heal,+10 to strength', runes: 'Fal,Ohm,Ort,Jah', applicable: 'axes, hammers', type: 'word', patch: '1.10'},

  { name: 'Flickering Flame', properties: 'Level 4-8 Resist Fire Aura When Equipped (varies),+3 To Fire Skills,-10-15% to Enemy Fire Resistance (varies),+30% Enhanced Defense,+30 Defense Vs. Missile,+50-75 To Mana (varies),Half Freeze Duration,+5% To Maximum Fire Resist,Poison Length Reduced by 50%', runes: 'Nef,Pul,Vex', applicable: 'helms', type: 'word', patch: '2.4'},

  { name: 'Fortitude (body armor)', properties: '20% chance to cast level 15 Chilling Armor when struck,+25% faster cast rate,+300% enhanced damage,+200% enhanced defense,+15 defense,+X to life (based on character level),Replenish life +7,+5% to maximum lightning resist,All resistances +25-30 (varies),Damage reduced by 7,12% damage taken goes to mana,+1 to light radius', runes: 'El,Sol,Dol,Lo', applicable: 'body armor', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Fortitude (weapon)', properties: '20% chance to cast level 15 Chilling Armor when struck,+25% faster cast rate,+300% enhanced damage,+9 minimum damage,+50 to attack rating,20% deadly strike,Hit causes monster to flee 25%,+200% enhanced defense,+X to life (based on character level),All resistances +25-30 (varies),12% damage taken goes to mana,+1 to light radius', runes: 'El,Sol,Dol,Lo', applicable: 'weapons', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Fury', properties: '+209% enhanced damage,40% increased attack speed,Prevent monster heal,66% chance of open wounds,33% deadly strike,Ignore target\'s defense,-25% target defense,20% bonus to attack rating,6% life stolen per hit,+5 to Frenzy (Barbarian only)', runes: 'Jah,Gul,Eth', applicable: 'melee weapons', type: 'word'},
  { name: 'Gloom', properties: '15% chance to cast level 3 Dim Vision when struck,+10% faster hit recovery,+200-260% enhanced defense (varies),+10 to strength,All resistance +45,Half freeze duration,5% damage taken goes to mana,-3 to light radius', runes: 'Fal,Um,Pul', applicable: 'body armor', type: 'word', patch: '1.10'},
  { name: 'Grief', properties: '35% chance to cast level 15 Venom on striking,+30-40% increased attack speed,Damage + 340-400,Ignore target\'s defense,-25% target defense,+1.875-185.625% damage to demons (based on character level),Adds 5-30 fire damage,-20-25% to enemy poison resistance (varies),20% deadly strike,Prevent monster heal,+2 to mana after each kill,+10-15 life after each kill (varies)', runes: 'Eth,Tir,Lo,Mal,Ral', applicable: 'swords, axes', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Hand of Justice', properties: '100% chance to cast level 36 Blaze when you level up,100% chance to cast level 48 Meteor when you die,Level 16 Holy Fire aura when equipped,+33% increased attack speed,+280-330% enhanced damage (varies),Ignore target\'s defense,7% life stolen per hit,-20% enemy fire resistance,20% deadly strike,Hit blinds target,Freezes target +3', runes: 'Sur,Cham,Amn,Lo', applicable: 'weapons', type: 'word', patch: '1.10'},
  { name: 'Harmony', properties: 'Level 10 Vigor aura when equipped,+200-275% enhanced damage (varies),+9 to minimum damage,+9 to maximize damage,Adds 55-160 lightning damage,Adds 55-160 fire damage,Adds 55-160 cold damage,+2-6 to Valkyrie (varies),+10 to dexterity,Regenerate mana 20%,+2 to mana after each kill,+2 to light radius,Level 20 Revive (25 charges)', runes: 'Tir,Ith,Sol,Ko', applicable: 'missile weapons', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Heart of the Oak', properties: '+3 to all skills,+40% faster cast rate,+75% damage to demons,+100 to attack rating against demons,Adds 3-14 cold damage (3 seconds),7% mana stolen per hit,+10 dexterity,Replenish life +20,Increase maximum mana 15%,All resistances +30-40 (varies),Level 4 Oak Sage (25 charges),Leven 14 Raven (60 charges)', runes: 'Ko,Vex,Pul,Thul', applicable: 'staves, maces', type: 'word', patch: '1.10'},
  { name: 'Holy Thunder', properties: '+60% enhanced damage,-25% target defense,Adds 5-30 fire damage,Adds 21-110 lightning damage,+75 poison damage over 5 seconds,+10 to maximum damage,Lighting resistance +60%,+5 maximum lightning resistance,+3 to Holy Shock (Paladin only),Level 7 Chain Lightning (60 charges)', runes: 'Eth,Ral,Ort,Tal', applicable: 'scepters', type: 'word'},
  { name: 'Honor', properties: '+160% enhanced damage,+9 minimum damage,+9 maximum damage,25% deadly strike,+250 to attack rating,+1 to all skills,7% life stolen per hit,Replenish life +10,+10 strength,+1 light radius,+2 to mana after each kill', runes: 'Amn,El,Ith,Tir,Sol', applicable: 'melee weapons', type: 'word'},
  { name: 'Ice', properties: '100% chance to cast level 40 Blizzard when you level up,25% chance to cast level 22 Frost Nova on striking,Level 18 Holy Freeze aura when equipped,+20% increased attack speed,+140-210% enhanced damage (varies),Ignore target\'s defense,+25-30% cold skill damage (varies),-20% to enemy cold skill resistance,7% life stolen per hit,20% deadly strike,3.125-309.375% extra gold from monsters (based on character level)', runes: 'Amn,Shael,Jah,Lo', applicable: 'missile weapons', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Infinity', properties: '50% chance to cast level 20 Chain Lightning when you kill an enemy,Level 12 Conviction aura when equipped,+35% faster run/walk,+255-325% enhanced damage (varies),-45-55% to enemy lightning resistance (varies),40% chance of crushing blow,Prevent monster heal,0.5-49.5 to vitality (based on character level),30% better chance of getting magic items,Level 21 Cyclone Armor (30 charges)', runes: 'Ber,Mal,Ber,Ist', applicable: 'polearms', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Insight', properties: 'Level 12-17 Meditation aura when equipped (varies),+35% faster cast rate,+200-260% enhanced damage (varies),+9 to minimum damage,180-250% bonus to attack rating (varies),Adds 5-30 fire damage,+75 poison damage over 5 seconds,+1-6 to Critical Strike (varies),+5 to all attributes,+2 to mana after each kill,23% better chance of getting magic items', runes: 'Ral,Tir,Tal,Sol', applicable: 'polearms, staves', type: 'word', patch: '1.10 Battle.net'},
  { name: 'King\'s Grace', properties: '+100% enhanced damage,+100% damage to demons,+50% damage to undead,Adds 5-30 fire damage,Adds 3-14 cold damage (3 seconds),+150 to attack rating,+100 to attack rating against demons,+100 to attack rating against undead,7% life stolen per hit', runes: 'Amn,Ral,Thul', applicable: 'swords, scepters', type: 'word'},
  { name: 'Kingslayer', properties: '+30% increased attack speed,+230-270% enhanced damage (varies),-25% target defense,20% bonus to attack rating,33% chance of crushing blow,50% chance of open wounds,+1 to Vengeance,Prevent monster heal,+10 to strength,40% extra gold from monsters', runes: 'Mal,Um,Gul,Fal', applicable: 'swords, axes', type: 'word', patch: '1.10'},
  { name: 'Last Wish', properties: '6% chance to cast level 11 Fade when struck,10% chance to cast level 18 Life Tap on striking,20% chance to cast level 20 Charged Bolt on attack,Level 17 Might aura when equipped,+330-375% enhanced damage (varies),Ignore target\'s defense,60-70% chance of crushing blow (varies),Prevent monster heal,Hit blinds target,+0.5-49.5% chance of getting magic items (based on character level)', runes: 'Jah,Mal,Jah,Sur,Jah,Ber', applicable: 'swords, hammers, axes', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Lawbringer', properties: '20% chance to cast level 15 Decrepify on striking,Level 16-18 Sanctuary aura when equipped (varies),-50% target defense,Adds 150-210 fire damage,Adds 130-180 cold damage,7% life stolen per hit,Slain monsters rest in peace,+200-250 missile defense (varies),+10 to dexterity,75% extra gold from monsters', runes: 'Amn,Lem,Ko', applicable: 'swords, hammers, scepter', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Leaf', properties: 'Adds 5-30 fire damage,+3 to fire skills,+3 to Fire Bolt (Sorceress only),+3 to Inferno (Sorceress only),+3 to Warmth (Sorceress only),+2 to mana after each kill,+2-198 to defense (based on character level),Cold resist +33%', runes: 'Tir,Ral', applicable: 'staves', type: 'word'},
  { name: 'Lionheart', properties: '+20% enhanced damage,Requirements -15%,+25 to strength,+10 to energy,+20 to vitality,+15 to dexterity,+50 to life,All resistances +30', runes: 'Hel,Lum,Fal', applicable: 'body armor', type: 'word'},
  { name: 'Lore', properties: '+1 to all skills,+10 to energy,+2 to mana after each kill,Lightning resist +30%,Damage reduced by 7,+2 to light radius', runes: 'Ort,Sol', applicable: 'helms', type: 'word'},
  { name: 'Malice', properties: '+33% enhanced damage,+9 maximum damage,100% chance of open wounds,-25% target defense,-100 to monster defense per hit,Prevent monster heal,+50 to attack rating,Drain life -5', runes: 'Ith,El,Eth', applicable: 'melee weapons', type: 'word'},
  { name: 'Melody', properties: '+50% enhanced damage,+300% damage to undead,+3 to bow and crossbow skills (Amazon only),+3 to Critical Strike (Amazon only),+3 to Dodge (Amazon only),+3 to Slow Missiles (Amazon only),20% increased attack speed,+10 to dexterity,Knockback', runes: 'Shael,Ko,Nef', applicable: 'missile weapons', type: 'word'},
  { name: 'Memory', properties: '+3 to Sorceress skill levels,33% faster cast rate,Increase maximum mana 20%,+3 to Energy Shield (Sorceress only),+2 to Static Field (Sorceress only),+10 to energy,+10 to vitality,+9 minimum damage,-25% target defense,Magic damage reduced by 7,+50% enhanced defense', runes: 'Lum,Io,Sol,Eth', applicable: 'staves', type: 'word'},

  { name: 'Mist', properties: 'Level 8-12 Concentration Aura When Equipped (varies),+3 To All Skills,20% Increased Attack Speed,+100% Piercing Attack,+325-375% Enhanced Damage (varies),+9 To Maximum Damage,20% Bonus to Attack Rating,Adds 3-14 Cold Damage,Freeze Target +3,+24 Vitality,All Resistances +40', runes: 'Cham,Shael,Gul,Thul,Ith', applicable: 'missile weapons', type: 'word', patch: '2.4'},
  //{ name: '', properties: '', runes: 'Dol,Ort,Eld,Lem', applicable: 'weapons', type: 'word', patch: '2.4'},

  { name: 'Myth', properties: '3% chance to cast level 1 Howl when struck,10% chance to cast level 1 Taunt on striking,+2 to Barbarian skill levels,+30 missile defense,Replenish life +10,Attacker takes damage of 14,Requirements -15%', runes: 'Hel,Amn,Nef', applicable: 'body armor', type: 'word', patch: '1.11'},
  { name: 'Nadir', properties: '+50% enhanced defense,+10 defense,+30 missile defense,Level 13 Cloak of Shadows (9 charges),+2 to mana after each kill,+5 to strength,-33% gold from monsters,-3 to light radius', runes: 'Nef,Tir', applicable: 'helms', type: 'word'},
  { name: 'Oath', properties: '30% chance to cast level 20 bone spirit on striking,Indestructible,+50% increased attack speed,+210-340% enhanced damage (varies),+75% damage to demons,+100 attack rating against demons,Prevent monster heal,+10 to energy,+10-15 magic absorb (varies),Level 16 Heart of Wolverine (20 charges),Level 17 Iron Golem (14 charges)', runes: 'Shael,Pul,Mal,Lum', applicable: 'swords, axes, maces', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Obedience', properties: '30% chance to cast level 21 Enchant when you kill an enemy,40% faster hit recovery,+370% enhanced damage,-25% target defense,Adds 3-14 cold damage (3 seconds),-25% to enemy fire resistance,40% chance of crushing blow,+200-300 defense (varies),+10 to strength,+10 to dexterity,All resistances +20-30 (varies),Requirements -20%', runes: 'Hel,Ko,Thul,Eth,Fal', applicable: 'polearms', type: 'word', patch: '1.10 Battle.net'},

  { name: 'Obsession', properties: 'Indestructible,24% Chance to cast level 10 Weaken when struck,+4 To All Skills,+65% Faster Cast Rate,+60% Faster Hit Recovery,Knockback,+10 To Vitality,+10 To Energy,Increase Maximum Life 15-25% (varies),Regenerate Mana 15-30% (varies),All Resistances +60-70 (varies),75% Extra Gold from Monsters,30% Better Chance of Getting Magic Items', runes: 'Zod,Ist,Lem,Lum,Io,Nef', applicable: 'staves', type: 'word', patch: '2.4'},

  { name: 'Passion', properties: '+25% increased attack speed,+160-210% enhanced damage (varies),50-80% bonus to attack rating (varies),+75% damage to demons,+50 attack rating against undead,Adds 1-50 lightning damage,+1 to Berserk,+1 to Zeal,Hit blinds target +10,Hit causes monster to flee 25%,75% extra gold from monsters,Level 3 Heart of Wolverine (12 charges)', runes: 'Dol,Ort,Eld,Lem', applicable: 'weapons', type: 'word', patch: '1.10'},

  { name: 'Pattern', properties: '+30% Faster Block Rate,+40-80% Enhanced Damage (varies),10% Bonus to Attack Rating,Adds 17-62 Fire Damage,Adds 1-50 Lightning Damage,Adds 3-14 Cold Damage,+75 Poison Damage Over 5 Seconds,+6 to Strength,+6 to Dexterity,All Resistances +15', runes: 'Tal,Ort,Thul', applicable: 'claws', type: 'word', patch: '2.4'},  

  { name: 'Peace', properties: '4% chance to cast level 5 Slow Missiles when struck,2% chance to cast level 15 Valkyrie on striking,+2 to Amazon skill levels,+20% faster hit recovery,+2 to Critical Strike,Cold resist +30%,Attacker takes damage of 14', runes: 'Shael,Thul,Amn', applicable: 'body armor', type: 'word', patch: '1.11'},
  { name: 'Phoenix (shield)', properties: '100% chance to cast level 40 Blaze when you level up,40% chance to cast level 22 Firestorm on striking,Level 10-15 Redemption aura when equipped (varies),+350-400 missile defense (varies),+350-400% enhanced damage (varies),-28% to enemy fire resistance,+50 to life,+5% maximum lightning resist,+10% maximum fire resist,+15-21 fire absorb (varies)', runes: 'Vex,Vex,Lo,Jah', applicable: 'shields', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Phoenix (weapon)', properties: '100% chance to cast level 40 Blaze when you level up,40% chance to cast level 22 Firestorm on striking,Level 10-15 Redemption aura when equipped (varies),+350-400% enhanced damage (varies),Ignore target\'s defense,14% mana stolen per hit,-28% to enemy fire resistance,20% deadly strike,+350-400 missile defense (varies),+15-21 fire absorb (varies)', runes: 'Vex,Vex,Lo,Jah', applicable: 'weapons', type: 'word', patch: '1.10 Battle.net'},
  
  { name: 'Plague', properties: '20% Chance to cast level 12 Lower Resist when struck,25% Chance to cast level 15 Poison Nova on striking,Level 13-17 Cleansing Aura When Equipped (varies),+1-2 All Skills,+20% Increased Attack Speed,+220-320% Enhanced Damage (varies),-23% To Enemy Poison Resistance,0.3% (0-29.7) Deadly Strike ,Based on Character Level).+25% Chance of Open Wounds,Freezes Target +3', runes: 'Cham,Shael,Um', applicable: 'swords', type: 'word', patch: '2.4'},

  { name: 'Pride', properties: '25% chance to cast level 17 Fire Ball when struck,Level 16-20 Concentrate aura when equipped (varies),260-300% bonus to attack rating (varies),+1-99% damage to demons (based on character level),Adds 50-280 lightning damage,20% deadly strike,Hit blinds target,Freezes target +3,+10 to vitality,Replenish life +8,1.875-185.625% extra gold from monsters (based on character level)', runes: 'Cham,Sur,Io,Lo', applicable: 'polearms', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Principle', properties: '100% chance to cast level 5 Holy Bolt on striking,+2 to Paladin skill levels,+50% damage to undead,+100-150 to life (varies),15% slower stamina drain,+5% maximum poison resist,Fire resist +30%', runes: 'Ral,Gul,Eld', applicable: 'body armor', type: 'word', patch: '1.11'},
  { name: 'Prudence', properties: '+25% faster hit recovery,+140-170% enhanced defense (varies),All resistance +25-35 (varies),Damage reduced by 3,Magic damage reduced by 17,+2 to mana after each kill,+1 to light radius,Repairs 1 durability in 14 seconds', runes: 'Mal,Tir', applicable: 'body armor', type: 'word', patch: '1.10'},
  { name: 'Radiance', properties: '+75% enhanced defense,+30 defense vs. missile,+10 to energy,+10 to vitality,15% damage goes to mana,Magic damage reduced by 3,+33 to mana,Damage reduced by 7,+5 light radius', runes: 'Nef,Sol,Ith', applicable: 'helms', type: 'word'},
  { name: 'Rain', properties: '5% chance to cast level 15 Cyclone Armor when struck,5% chance to cast level 15 Twister on striking,+2 to Druid skill levels,+100-150 mana (varies),Lightning resist +30%,Magic damage reduced by 7,15% damage taken goes to mana', runes: 'Ort,Mal,Ith', applicable: 'body armor', type: 'word', patch: '1.11'},
  { name: 'Rhyme', properties: '20% increased chance of blocking,40% faster block rate,All resistances +25,Regenerate mana 15%,Cannot be frozen,50% extra gold from monsters,25% better chance of getting magic items', runes: 'Shael,Eth', applicable: 'shields', type: 'word'},
  { name: 'Rift', properties: '20% chance to cast Level 16 Tornado on striking,16% chance to cast level 21 Frozen Orb on attack,20% bonus to attack rating,Adds 160-250 magic damage,Adds 60-180 fire damage,+5-10 to all stats (varies),+10 to dexterity,38% damage taken goes to mana,75% extra gold from monsters,Level 15 Iron Maiden (40 charges),Requirements -20%', runes: 'Hel,Ko,Lem,Gul', applicable: 'polearms, scepters', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Sanctuary', properties: '+20% faster hit recovery,+20% faster block rate,20% increased chance of blocking,+130-160% enhanced defense (varies),+250 defense vs. missile,+20 to dexterity,All resistance +50-70 (varies),Magic damage reduced by 7,Level 12 Slow Missiles (60 charges)', runes: 'Ko,Ko,Mal', applicable: 'shields', type: 'word', patch: '1.10'},
  { name: 'Silence', properties: '200% enhanced damage,+75% damage to undead,Requirements -20%,20% increased attack speed,+50 to attack rating against undead,+2 to all skills,All resistances +75,20% faster hit recovery,11% mana stolen per hit,Hit causes monsters to flee 25%,Hit blinds target +33,+2 to mana after each kill,30% better chance of getting magic items', runes: 'Dol,Eld,Hel,Ist,Tir,Vex', applicable: 'weapons', type: 'word'},
  { name: 'Smoke', properties: '+75% enhanced defense,+280 missile defense,All resistances +50,20% faster hit recovery,Level 6 Weaken (18 charges),+10 to energy,-1 to light radius', runes: 'Nef,Lum', applicable: 'body armor', type: 'word'},
  { name: 'Spirit (shield)', properties: '+2 to all skills,+25-35% faster cast rate,+55% faster hit recovery,+250 missile defense,+22 to vitality,+89-112 to mana (varies),Cold resist +35%,Lightning resist +35%,Poison resist +35%,+3-8 magic absorb (varies),Attacker takes damage of 14', runes: 'Tal,Thul,Ort,Amn', applicable: 'shields', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Spirit (sword)', properties: '+2 to all skills,+25-35% faster cast rate,+55% faster hit recovery,Adds 1-50 lightning damage,Adds 3-14 cold damage (3 seconds),+75 poison damage over 5 seconds,7% life stolen per hit,+250 missile defense,+22 to vitality,89-112 to mana (varies),+3-8 magic absorb (varies)', runes: 'Tal,Thul,Ort,Amn', applicable: 'swords', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Splendor', properties: '+1 to all skills,+10% faster cast rate,+20% faster block rate,+60-100% enhanced defense (varies),+10 to energy,Regenerate mana 15%,50% extra gold from monsters,20% better chance of getting magic items,+3 to light radius', runes: 'Eth,Lum', applicable: 'shields', type: 'word', patch: '1.10'},
  { name: 'Stealth', properties: 'Magic damage reduced by 3,+6 to dexterity,+15 to maximum stamina,Poison resist +30%,Regenerate mana 15%,25% faster run/walk,25% faster cast rate,25% faster hit recovery', runes: 'Tal,Eth', applicable: 'body armor', type: 'word'},
  { name: 'Steel', properties: '20% enhanced damage,+3 minimum damage,+3 maximum damage,+50 to attack rating,50% chance of open wounds,25% increased attack speed,+2 to mana after each kill,+1 to light radius', runes: 'Tir,El', applicable: 'swords, axes, maces', type: 'word'},
  { name: 'Stone', properties: '+60% faster hit recovery,+250-290% enhanced defense (varies),+300 missile defense,+16 to strength,+16 to vitality,+10 to energy,All resistances +15,Level 16 Molten Boulder (80 charges),Level 16 Clay Golem (16 charges)', runes: 'Shael,Um,Pul,Lum', applicable: 'body armor', type: 'word', patch: '1.10'},
  { name: 'Strength', properties: '35% enhanced damage,25% chance of crushing blow,7% life stolen per hit,+2 to mana after each kill,+20 to strength,+10 to vitality', runes: 'Amn,Tir', applicable: 'melee weapons', type: 'word'},
  { name: 'Treachery', properties: '5% chance to cast level 15 Fade when struck,25% chance to cast level 15 Venom on striking,+2 to Assassin skill levels,+45% increased attack speed,+20% faster hit recovery,Cold resist 30%,50% extra gold from monsters', runes: 'Shael,Thul,Lem', applicable: 'body armor', type: 'word', patch: '1.11'},

  { name: 'Unbending Will', properties: '18% Chance to cast Level 18 Taunt on striking,+3 To Combat Skills (Barbarian Only),+20-30% Increased Attack Speed (varies),+300-350% Enhanced Damage (varies),+9 To Maximum Damage,+50 To Attack Rating,+75% Damage to Undead,+50 Attack Rating Against Undead,8-10% Life Stolen Per Hit (varies),Prevent Monster Heal,+10 To Strength,+10 To Vitality,Damage Reduced By 8,+1 Light Radius,Requirements -20%', runes: 'Fal,Io,Ith,Eld,El,Hel', applicable: 'swords', type: 'word', patch: '2.4'},

  { name: 'Venom', properties: 'Hit causes monsters to flee 25%,Prevent monster heal,Ignore target\'s defense,7% mana stolen per hit,Level 15 Poison Explosion (27 charges),Level 13 Poison Nova (11 charges),+273 poison damage over 6 seconds', runes: 'Tal,Dol,Mal', applicable: 'weapons', type: 'word'},
  { name: 'Voice of Reason', properties: '15% chance to cast level 13 Frozen Orb on striking,18% chance to cast level 20 Ice Blast on striking,+50 to attack rating,+220-350% damage to demons,+355-375% damage to undead (varies),+50 to attack rating against undead,Adds 100-220 cold damage,-24% to enemy cold resistance,+10 to dexterity,Cannot be frozen,75% extra gold from monsters,+1 to light radius', runes: 'Lem,Ko,El,Eld', applicable: 'swords, maces', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Wealth', properties: '300% extra gold from monsters,100% better chance of getting magic items,+2 to mana after each kill,+10 to dexterity', runes: 'Lem,Ko,Tir', applicable: 'body armor', type: 'word'},
  { name: 'White', properties: 'Hit causes monster to flee 25%,+10 to vitality,+3 to poison and bone skills (Necromancer only),+2 to Bone Spear (Necromancer only),+3 to Bone Armor (Necromancer only),+4 to Skeleton Master (Necromancer only),Magic damage reduced by 4,20% faster cast rate,+13 to mana', runes: 'Dol,Io', applicable: 'wand', type: 'word'},
  { name: 'Wind', properties: '10% chance to cast level 9 Tornado on striking,+20% faster run/walk,+40% increased attack speed,+15% faster hit recovery,+120-160% enhanced damage (varies),-50% target defense,+50 to attack rating,Hit blinds target,+1 to light radius,Level 13 Twister (127 charges)', runes: 'Sur,El', applicable: 'melee weapons', type: 'word', patch: '1.10'},

  { name: 'Wisdom', properties: '+33% Piercing Attack,+15-25% Bonus to Attack Rating (varies),4-8% Mana Stolen Per Hit (varies),+30% Enhanced Defense,+10 Energy,15% Slower Stamina Drain,Cannot Be Frozen,+5 Mana After Each Kill,15% Damage Taken Goes to Mana', runes: 'Pul,Ith,Eld', applicable: 'helms', type: 'word', patch: '2.4'},
//{ name: '', properties: '', runes: 'Dol,Ort,Eld,Lem', applicable: 'weapons', type: 'word', patch: '2.4'},

  { name: 'Wrath', properties: '30% chance to cast level 1 Decrepify on striking,5% chance to cast level 10 Life Tap on striking,+375% damage to demons,+100 attack rating against demons,+250-300% damage to undead (varies),Adds 85-120 magic damage,Adds 41-240 lightning damage,20% chance of crushing blow,Prevent monster heal,+10 to energy,Cannot be frozen', runes: 'Pul,Lum,Ber,Mal', applicable: 'missile weapons', type: 'word', patch: '1.10 Battle.net'},
  { name: 'Zephyr', properties: '+33% enhanced damage,+66 to attack rating,Adds 1-50 lightning damage,-25% target defense,+25 defense,25% faster run/walk,25% increased attack speed,7% chance to cast level 1 Twister when struck', runes: 'Ort,Eth', applicable: 'missile weapons', type: 'word'},

];

export default data