export class Item
{
  name;
  properties;
  type;
  imgSrc;
  level;

  constructor(name, properties = {}, type=undefined, level=undefined)
  {
    this.name = name;
    this.properties = properties;
    this.type = type;
    this.level = level;

    this.imgSrc = 'assets/images/' + this.type + '/' + this.name.toLowerCase() + '.png';
  }
}

export class CraftableItem extends Item
{
  ingredients;

  constructor(name, properties = {}, type=undefined, ingredients = [], level=undefined)
  {
    super(name, properties, type, level);
    this.ingredients = ingredients;
  }
}

export class Items
{
  items;

  constructor(items=[])
  {
    this.items = items;
  }

  add(item)
  {
    this.items.push(item);
  }

  get(name)
  {
    return this.byName(name);
  }

  byType(type)
  {
    return this.items.filter(item => item.type == type);
  }

  byName(name)
  {
    return this.items.find(item => item.name == name);
  }

  byCraftable()
  {
    return this.items.filter(item => item instanceof CraftableItem)
  }

  /**
   * ingredients: Item[]
   */
  byAnyIngredients(ingredients)
  {
    return this.items.filter(item => {
      for(let itemIngredient of item.ingredients)
      {
        if(ingredients.find(ingrediant => ingrediant == itemIngredient.item))
        {
          return true;
        }
      }

      return false;
    })
  }

  byAllIngredients(ingrediants)
  {
    return this.items.filter(item => {

    });
  }

  hasAll(items)
  {
    
  }

  all()
  {
      return this.items;
  }
}