<template>
        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content" v-if="modalItem">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ modalItem.name }} detail</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div v-if="modalItem">
                  <ItemDetail :item="modalItem" />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row gx-5 gx-md-3 gx-lg-4 gx-xl-5 text-block" style="padding-bottom: 12px;">
          <div class="col d-md-inline-block d-sm-none d-none">
            <div>
              Welcome to diablo 2 resurrected runeword and transmute calculator. Click on rune image to add it into your inventory. To see rune detail click on rune name.
              All availabe runes will be displayed on the top of the list. Every inventory change will update URL so all your inventory will be persisted as long as you keep the URL.                      
            </div>
          </div>
          <div class="col d-lg-inline-block d-none d-xs-none">
            In order to create a runeword runes MUST be socketed in CORRECT ORDER and used in items of GIVEN TYPE and with the EXACT SOCKET COUNT. Otherwise runeword will not be created!
            For more information about rune mechanics please read the game guide.
          </div>
        </div>

        <div class="row gx-md-3 gx-lg-3 gx-xl-4 gx-0" style="padding-top: 12px;" id="app">

        <div class="col-md-6 col-sm-12 col-xs-12" style="text-align: center;">
          <div class="row gx-0">
            <div class="col">
              <input type="text" class="form-control" placeholder="search rune" v-model="searchRune">
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col">
              <div :key="rune.name" v-for="rune in filterRunes()" class="p-1 p-md-1 p-lg-2" style="display: inline-block; cursor: pointer;">
                <RuneDetail :item="rune" :inventory="this.inventory" :highlight="highlight" @display-item="displayItem" />
              </div>
            </div>
          </div>
        </div>

      <div class="col-md-6 col-12 py-4 py-md-0" style="text-align: center;">
        <div class="row">
          <div class="col-md-4 py-1 py-md-0">
            <input type="text" class="form-control" placeholder="search runeword" v-model="searchRunewords">
          </div>

          <div class="col-md-4 py-1 py-md-0">
            <select class="form-select" v-model="searchApplication">
              <option selected value="">application</option>
              <option value="weapon">melee weapons</option>
              <option value="ranged">ranged weapons</option>
              <option value="armor">body armor</option>
              <option value="helmet">helmet</option>
              <option value="shield">shields</option>
            </select>
          </div>

          <div class="col-md-4 py-1 py-md-0">
            <select class="form-select" v-model="searchCount">
              <option selected value="">socket count</option>
              <option value="2">2 sockets</option>
              <option value="3">3 sockets</option>
              <option value="4">4 sockets</option>
              <option value="5">5 sockets</option>
              <option value="6">6 sockets</option>
            </select>
          </div>
        </div>
        <hr>
        <!-- <h3 v-if="runewords.createable.length">Available</h3>
        <div :key="item.runeword.name" v-for="item in getCreateableRunewords()">
          <RunewordDetail :item="item.runeword" :quantity="item.quantity" @onRuneOver="highlightRune" />
        </div> -->
        <!-- <h3>All</h3> -->
        <div class="row" style="max-height: 120vh; overflow-y: auto">
          <div class="col">
            <div :key="item" v-for="item in getRunewords()">
              <RunewordDetail :item="item" :missing="item.missing" @on-rune-over="highlightRune" @display-item="displayItem" />
            </div>
          </div>
        </div>
      </div>
    </div>
    
</template>

<script>
import ItemDetail from "./ItemDetail";
import InventoryItem from "./InventoryItem";
import RunewordDetail from "./RunewordDetail";
import RuneDetail from "./RuneDetail";
import ItemsData from "../data/items";
import WordsData from "../data/words";
import {Item, Items, CraftableItem} from "../classes";


//ITEM TYPES
const ITEM_GEM = 'gem';
const ITEM_RUNE = 'rune';
const ITEM_RUNEWORD = 'word';

//items
// const RUNE_EL = 'El';
// const RUNE_ELD = 'Eld';
// const RUNE_TIR = 'Tir';
// const RUNE_NEF = 'Nef';
// const RUNE_ETH = 'Eth';
// const RUNE_ITH = 'Ith';
// const RUNE_TAL = 'Tal';
// const RUNE_RAL = 'Ral';
// const RUNE_ORT = 'Ort';
// const RUNE_THUL = 'Thul';
// const RUNE_AMN = 'Amn';
// const RUNE_SOL = 'Sol';
// const RUNE_SHAEL = 'Shael';
// const RUNE_DOL = 'Dol';
// const RUNE_HEL = 'Hel';
// const RUNE_IO = 'Io';
// const RUNE_LUM = 'Lum';
// const RUNE_KO = 'Ko';
// const RUNE_FAL = 'Fal';
// const RUNE_LEM = 'Lem';
// const RUNE_PUL = 'Pul';
// const RUNE_UM = 'Um';
// const RUNE_MAL = 'Mal';
// const RUNE_IST = 'Ist';
// const RUNE_GUL = 'Gul';
// const RUNE_VEX = 'Vex';
// const RUNE_OHM = 'Ohm';
// const RUNE_LO = 'Lo';
// const RUNE_SUR = 'Sur';
// const RUNE_BER = 'Ber';
// const RUNE_JAH = 'Jah';
// const RUNE_CHAM = 'Cham';
// const RUNE_ZOD = 'Zod';

// const GEM_CHIPPED_AMETHYST = 'Chipped amethyst';
// const GEM_FLAWED_AMETHYST = 'Flawed amethyst';
// const GEM_AMETHYST = 'Amethyst';
// const GEM_FLAWLESS_AMETHYST = 'Flawless amethyst';
// const GEM_PERFECT_AMETHYST = 'Perfect amethyst';
// const GEM_CHIPPED_DIAMOND = 'Chipped diamond';
// const GEM_FLAWED_DIAMOND = 'Flawed diamond';
// const GEM_DIAMOND = 'Diamond';
// const GEM_FLAWLESS_DIAMOND = 'Flawless diamond';
// const GEM_PERFECT_DIAMOND = 'Perfect diamond';
// const GEM_CHIPPED_EMERALD = 'Chipped emerald';
// const GEM_FLAWED_EMERALD = 'Flawed emerald';
// const GEM_EMERALD = 'Emerald';
// const GEM_FLAWLESS_EMERALD = 'Flawless emerald';
// const GEM_PERFECT_EMERALD = 'Perfect emerald';
// const GEM_CHIPPED_RUBY = 'Chipped ruby';
// const GEM_FLAWED_RUBY = 'Flawed ruby';
// const GEM_RUBY = 'Ruby';
// const GEM_FLAWLESS_RUBY = 'Flawless ruby';
// const GEM_PERFECT_RUBY = 'Perfect ruby';
// const GEM_CHIPPED_SAPPHIRE = 'Chipped sapphire';
// const GEM_FLAWED_SAPPHIRE = 'Flawed sapphire';
// const GEM_SAPPHIRE = 'Sapphire';
// const GEM_FLAWLESS_SAPPHIRE = 'Flawless sapphire';
// const GEM_PERFECT_SAPPHIRE = 'Perfect sapphire';
// const GEM_CHIPPED_TOPAZ = 'Chipped topaz';
// const GEM_FLAWED_TOPAZ = 'Flawed topaz';
// const GEM_TOPAZ = 'Topaz';
// const GEM_FLAWLESS_TOPAZ = 'Flawless topaz';
// const GEM_PERFECT_TOPAZ = 'Perfect topaz';
// const GEM_CHIPPED_SKULL = 'Chipped skull';
// const GEM_FLAWED_SKULL = 'Flawed skull';
// const GEM_SKULL = 'Skull';
// const GEM_FLAWLESS_SKULL = 'Flawless skull';
// const GEM_PERFECT_SKULL = 'Perfect skull';

//RUNEWORDS

// const WORD_ANCIENTS_PLEDGE = 'Ancient\'s Pledge';
// const WORD_BEAST = 'Beast';
// const WORD_BLACK = 'Black';
// const WORD_BONE = 'Bone';
// const WORD_BRAMBLE = 'Bramble';
// const WORD_BRAND = 'Brand';
// const WORD_BREATH_OF_THE_DYING = 'Breath of the Dying';
// const WORD_CALL_TO_ARMS = 'Call to Arms';
// const WORD_CHAINS_OF_HONOR = 'Chains of Honor';
// const WORD_CHAOS = 'Chaos';
// const WORD_CRESCENT_MOON = 'Crescent Moon';
// const WORD_DEATH = 'Death';
// const WORD_DELIRIUM = 'Delirium';
// const WORD_DESTRUCTION = 'Destruction';
// const WORD_DOOM = 'Doom';
// const WORD_DRAGON = 'Dragon';
// const WORD_DREAM = 'Dream';
// const WORD_DURESS = 'Duress';
// const WORD_EDGE = 'Edge';
// const WORD_ENIGMA = 'Enigma';
// const WORD_ENLIGHTENMENT = 'Enlightenment';
// const WORD_ETERNITY = 'Eternity';
// const WORD_EXILE = 'Exile';
// const WORD_FAITH = 'Faith';
// const WORD_FAMINE = 'Famine';
// const WORD_FORTITUDE_SHIELD = 'Fortitude (shield)';
// const WORD_FORTITUDE_WEAPON = 'Fortitude (weapon)';
// const WORD_FURY = 'Fury';
// const WORD_GLOOM = 'Gloom';
// const WORD_GRIEF = 'Grief';
// const WORD_HAND_OF_JUSTICE = 'Hand of Justice';
// const WORD_HARMONY = 'Harmony';
// const WORD_HEART_OF_THE_OAK = 'Heart of the Oak';
// const WORD_HOLY_THUNDER = 'Holy Thunder';
// const WORD_HONOR = 'Honor';
// const WORD_ICE = 'Ice';
// const WORD_INFINITY = 'Infinity';
// const WORD_INSIGHT = 'Insight';
// const WORD_KINGS_GRACE = 'King\'s Grace';
// const WORD_KINGSLAYER = 'Kingslayer';
// const WORD_LAST_WISH = 'Last Wish';
// const WORD_LAWBRINGER = 'Lawbringer';
// const WORD_LEAF = 'Leaf';
// const WORD_LIONHEART = 'Lionheart';
// const WORD_LORE = 'Lore';
// const WORD_MALICE = 'Malice';
// const WORD_MELODY = 'Melody';
// const WORD_MEMORY = 'Memory';
// const WORD_MYTH = 'Myth';
// const WORD_NADIR = 'Nadir';
// const WORD_OATH = 'Oath';
// const WORD_OBEDIENCE = 'Obedience';
// const WORD_PASSION = 'Passion';
// const WORD_PEACE = 'Peace';
// const WORD_PHOENIX_SHIELD = 'Phoenix (shield)';
// const WORD_PHOENIX_WEAPON = 'Phoenix (weapon)';
// const WORD_PRIDE = 'Pride';
// const WORD_PRINCIPLE = 'Principle';
// const WORD_PRUDENCE = 'Prudence';
// const WORD_RADIANCE = 'Radiance';
// const WORD_RAIN = 'Rain';
// const WORD_RHYME = 'Rhyme';
// const WORD_RIFT = 'Rift';
// const WORD_SANCTUARY = 'Sanctuary';
// const WORD_SILENCE = 'Silence';
// const WORD_SMOKE = 'Smoke';
// const WORD_SPIRIT_SHIELD = 'Spirit (shield)';
// const WORD_SPIRIT_SWORD = 'Spirit (sword)';
// const WORD_SPLENDOR = 'Splendor';
// const WORD_STEALTH = 'Stealth';
// const WORD_STEEL = 'Steel';
// const WORD_STONE = 'Stone';
// const WORD_STRENGTH = 'Strength';
// const WORD_TREACHERY = 'Treachery';
// const WORD_VENOM = 'Venom';
// const WORD_VOICE_OF_REASON = 'Voice of Reason';
// const WORD_WEALTH = 'Wealth';
// const WORD_WHITE = 'White';
// const WORD_WIND = 'Wind';
// const WORD_WRATH = 'Wrath';
// const WORD_ZEPHYR = 'Zephyr';






export class Runeword extends CraftableItem
{
  applicable;
  runes;
  //missing runes to create given word
  missing;
  patch;

  constructor(name, properties=[], runes=[], applicable=[], patch=undefined)
  {
    let ingredients = [];

    for(let rune of runes)
    {
      let existing = ingredients.find(item => item.item.name == rune.name);

      existing 
        ? existing.quantity += 1
        : ingredients.push({item: rune, quantity: 1});
    }

    super(name, properties, ITEM_RUNEWORD, ingredients);
    this.applicable = applicable;
    this.runes = runes;
    this.missing = [];
    this.patch = patch;
  }

  updateMissing()
  {
    this.missing = this.ingredients
      .filter(ingredient => !inventory.get(ingredient.item) ? true : false)
      .map(ingredient => ingredient.item.name);
  }
}

const APPLICATION = {
  weapon: ['weapons', 'axes', 'scepters', 'hammers', 'maces', 'clubs', 'claws', 'swords', 'polearms', 'staves', 'wands', 'melee weapons'],
  ranged: ['missile weapons', 'weapons'],
  armor: ['body armor'],
  helmet: ['helms'],
  shield: ['shields', "paladin shields"]
}



let items = new Items();

export class Runewords
{  
  items;
  createable;

  constructor(items=[])
  {
    this.items = items;
  }

  add(runeword)
  {
    this.items.push(runeword);
  }

  get(name)
  {
    this.items.find(item => item.name == name);
  }

  canCreate()
  {
    this.items.filter(item => item.ingredients.every(rune => inventory.get(rune)));
  }

  updateCreateable()
  {
    this.createable = [];
    
    this.items.forEach(runeword => {
      let count = inventory.canCraft(runeword);

      runeword.updateMissing();

      if(count > 0)
      {  
        this.createable.push({runeword: runeword, quantity: count});
      }
    });
  }
}

export class Inventory
{
  items;
  craftable=[];
  changes = [];

  constructor(items=[])
  {
    this.items = items;
    this.changes.push(()=>this.updateCraftable());
  }

  set(item, quantity)
  {
    let existing = this.get(item);

    if(existing && quantity <= 0)
    {
      this.items.splice(this.items.indexOf(existing), 1);
    }
    else if(quantity > 0)
    {
      existing 
        ? existing.quantity = quantity
        : this.items.push({item: item, quantity: quantity});
    }

    this.inventoryHasChanged();
  }

  add(item, quantity=1)
  {
    //find if already exists in inventory
    let existing = this.get(item);

    if(existing)
    {
      existing.quantity + quantity <= 0
        ? this.items.splice(this.items.indexOf(existing), 1)
        : existing.quantity += quantity;
    }
    else
    {
      this.items.push({item: item, quantity: quantity});
    }

    this.inventoryHasChanged();
  }

  get(item, nimQuantity=undefined)
  {
    return nimQuantity && nimQuantity > 0 
      ? this.items.find(inInventory => inInventory.item.name == item.name && nimQuantity.quantity >= quantity)
      : this.items.find(inInventory => inInventory.item.name == item.name)
  }

  /**
   * returns number of items that can be crafted from inventory
   */
  canCraft(item)
  {
    if(!item.ingredients || !item.ingredients.length)
    {
      return 0;
    }

    let counts = [];

    for(let ingredient of item.ingredients)
    {
      let existing = this.get(ingredient.item);

      if(!existing || existing.quantity < ingredient.quantity)
      {
        return 0;
      }

      counts.push(Math.floor(existing.quantity / ingredient.quantity))
    }

    return Math.min(...counts);
  }  

  clear(item)
  {
    let inventoryItem = this.get(item);
    
    if(inventoryItem)
    {
      this.items.splice(this.items.indexOf(inventoryItem), 1);
    }

    this.inventoryHasChanged();
  }

  inventoryHasChanged()
  {
    this.changes.forEach(callback => callback(this));
  }

  updateCraftable()
  {
    this.craftable = [];

    items.byCraftable().forEach(item => {
      let count = inventory.canCraft(item);

      if(count > 0)
      {
        this.craftable.push({item: item, quantity: count});
      }
    });
  }
}

let inventory = new Inventory();
let runewords = new Runewords();

export default {
  name: 'Home',
  components: {
    ItemDetail,
    InventoryItem,
    RunewordDetail,
    RuneDetail
  },

  data()
  {
    return {
      inventory: inventory,
      items: items,
      runewords: runewords,
      // inventoryForm: {},
      // /search: '',
      // searchInventory: '',
      searchRunewords: '',
      searchApplication: '',
      searchCount: '',
      searchType: '',
      searchRune: '',
      highlight: '',
      modalItem: undefined
    }
  },

  created()
  {
    document.getElementById('link-home').classList.add('active');
    initData();
    this.inventory.changes.push(()=>this.runewords.updateCreateable());
    initInventory(this.$route.query);
    //modify url
    this.inventory.changes.push((inventory)=>this.updateUrl(inventory));

    runewords.updateCreateable();
  },

  methods:{
    highlightRune(name)
    {
      this.highlight = name;
    },
    displayItem(item)
    {
      this.modalItem = item;
    },
    filterRunes()
    {
      return this.items.items.filter(item => item.type == ITEM_RUNE)
        .filter(item => !this.searchRune || item.name.toLowerCase().includes(this.searchRune.toLowerCase()));
    },
    getCreateableRunewords()
    {
      return this.searchRunewords || this.searchApplication || this.searchCount
        ? runewords.createable
            .filter(item => !this.searchRunewords || item.runeword.name.toLowerCase().includes(this.searchRunewords.toLowerCase()))
            .filter(item => !this.searchApplication || item.runeword.applicable.some(item=>APPLICATION[this.searchApplication].includes(item)))
            .filter(item => !this.searchCount || item.runeword.runes.length == this.searchCount)
        : runewords.createable
    },
    getRunewords()
    {
      let filtered = this.searchRunewords || this.searchApplication || this.searchCount
        ? runewords.items
            .filter(item => item.name.toLowerCase().includes(this.searchRunewords.toLowerCase()))
            .filter(item => !this.searchApplication || item.applicable.some(item=>APPLICATION[this.searchApplication].includes(item)))
            .filter(item => !this.searchCount || item.runes.length == this.searchCount)
        : runewords.items;

      return filtered.sort((first, second) => {
        let canCreateFirst = runewords.createable.find(createable => createable.runeword.name == first.name);
        let canCreateSecond = runewords.createable.find(createable => createable.runeword.name == second.name);

        if(canCreateFirst && canCreateSecond)
        {
          return 0;
        }

        return !canCreateFirst ? 1 : -1;
      });
    },
    updateUrl()
    {
      let params = {};

      for(let inInventory of inventory.items)
      {
        let key  = 'i' + items.items.indexOf(items.byName(inInventory.item.name));
        params[key]=inInventory.quantity;
      }
      
      this.$router.replace({query: params})
    }
  },
}

function initInventory(data)
{
  for(let key in data)
  {
    if(key.startsWith('i'))
    {
      let index = parseInt(key.substring(1, key.length));
      let quantity = parseInt(data[key]);

      if(index >= 0  && quantity && quantity <= 99 && items.items.hasOwnProperty(index))
      {
        let item = items.items[index];

        // and is not already in inventory
        if(item && !inventory.items.find(inInventory=>inInventory.item == item))
        {
          inventory.add(item, quantity);
        }
      }
    }
  }
}

function initData()
{
  let data = ItemsData

  for(let itemData of data)
  {
    let item = itemData.recipe
      ? new CraftableItem(itemData.name, ['weapon: ' + itemData.weapon, 'armour: ' + itemData.armour], itemData.type, getItemIngredients(itemData.recipe), itemData.level)
      : new Item(itemData.name, ['weapon: ' + itemData.weapon, 'armour: ' + itemData.armour], itemData.type, itemData.level);

    items.add(item);
  }

  let words = WordsData;

  for(let wordData of words)
  {
    let runeword = new Runeword(wordData.name, getItemProperties(wordData.properties), getRunes(wordData.runes), wordData.applicable.split(','), wordData.patch);

    runewords.add(runeword);
  }
}

function getItemIngredients(data)
{
  return data.split(',').map(item => {
    let prop = item.split('-');
    return {quantity: parseInt(prop[0]), item: items.get(prop[1])}
  })
}

function getItemProperties(data)
{
  return data.split(',');
}

function getRunes(data)
{
  return data.split(',').map(item => items.byName(item));
}

</script>

<style>
.form-control, .form-select, .card, .modal-content
{
  background-color: #ced4da;
  color: #212529;
}

.card
{
  border: 4px;
}

.accordion-collapse
{
  background-color: #ececec;
}

.btn-primary
{
    background-color: darkblue;
    border-color: darkblue;
}

.btn-danger
{
    background-color: darkred;
    border-color: darkred;
}

.accordion-button:not(.collapsed) {
    /* color: #212529; */
    color: darkblue;
    font-weight: 500;
    background-color: #ced4da;
    border-color: #ced4da;
}

.accordion-button.collapsed {
    color: #212529;
    font-weight: 500;
    background-color: #ececec;
    border-color: #ced4da;
}

.accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0,0,0,.125);
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    
}

.rune-container
{
  display: inline-block;
}

.form-control:focus, .form-select:focus 
{
  border-color: rgb(160, 53, 0);
  box-shadow: 0 0 0 0.25rem rgb(160 53 0 / 25%);
}

/* 160 53 0  */
</style>