<template>
<div class="row">
    <div class="col-12 py-4">
        <h2>List of all runes</h2>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="table-responsive">
            <table v-if="items" class="table table-dark table-striped table-hover" style="vertical-align: middle;">
                <thead style="height: 60px; vertical-align: middle">
                    <tr>
                        <th>Picture</th>
                        <th>Name</th>
                        <th>Level</th>
                        <th>Wepon</th>
                        <th>Armor</th>
                        <th>Shield</th>
                        <th>Crafted from</th>
                    </tr>
                </thead>
                <tbody>
                    <tr :key="rune.name" v-for="rune in items.byType('rune')">
                        <td><img :src="rune.imgSrc" :alt="rune.name" width="42"></td>
                        <td>{{ rune.name }}</td>
                        <td>{{ rune.level }}</td>
                        <td>{{ rune.properties.weapon }}</td>
                        <td>{{ rune.properties.armour }}</td>
                        <td>{{ rune.properties.shield }}</td>
                        <td>{{ getCraftedFrom(rune) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
import ItemsData from "../../data/items";
import {Item, Items, CraftableItem} from "../../classes";

function getItemIngredients(data, items)
{
  return data.split(',').map(item => {
    let prop = item.split('-');
    return {quantity: parseInt(prop[0]), item: items.get(prop[1])}
  })
}

function getItemPrperties(data)
{
    let properties = {weapon: data.weapon, armour: data.armour};

    properties.shield = data.shield
        ? data.shield
        : data.armour;

    return properties;
}

export default {
  name: 'RuneList',
  components: {},

  data()
  {
    return {
        items: new Items([])
    }
  },

  created()
  {
      document.getElementById('link-runes').classList.add('active');
      
      ItemsData        
        .forEach(item => {
            let newItem = item.recipe
                ? new CraftableItem(item.name, getItemPrperties(item), item.type, getItemIngredients(item.recipe, this.items), item.level)
                : new Item(item.name, getItemPrperties(item), item.type, item.level);

            this.items.add(newItem);   
        });    
  },
  methods: {
      getCraftedFrom(rune)
      {
          return rune.ingredients 
                ? rune.ingredients.map(ingredient => ingredient.quantity + 'x ' + ingredient.item.name).join(', ') 
                : 'N/A';
      }
  }
}

</script>

<style>

</style>