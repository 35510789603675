<template>
<div class="row">
    <div class="col-12 py-4">
        <h2>Experience gains by level</h2>
    </div>
</div>

<div class="row justify-content-md-center">
    <div class="col-12 col-md-6 col-lg-2 px-sm-2">
        <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="your level" v-model="searchLevel">
            <div class="input-group-append">
                <button type="button" class="btn btn-dark" @click="scrollToLevel()" :disabled="!searchLevel">show</button>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12 px-sm-2">
        <div class="table-responsive">
            <table  class="table table-dark xp-table" style="vertical-align: middle;">
                <thead style="height: 60px; vertical-align: middle">
                    <tr>
                        <th>Lvl</th>

                        <th>No1</th>
                        <th>No2</th>
                        <th>No3</th>
                        <th>No4</th>
                        <th>No5</th>

                        <th>Ni1</th>
                        <th>Ni2</th>
                        <th>Ni3</th>
                        <th>Ni4</th>
                        <th>Ni5</th>

                        <th>He1</th>
                        <th>He2</th>
                        <th>He3</th>
                        <th>He4</th>
                        <th>He5</th>
                    </tr>
                </thead>
                <tbody>
                    <template :key="index" v-for="(xpItems, index) in items">
                        <tr v-if="index > 0 && (index) % 20 == 0">
                            <th>Lvl</th>

                            <th>No1</th>
                            <th>No2</th>
                            <th>No3</th>
                            <th>No4</th>
                            <th>No5</th>

                            <th>Ni1</th>
                            <th>Ni2</th>
                            <th>Ni3</th>
                            <th>Ni4</th>
                            <th>Ni5</th>

                            <th>He1</th>
                            <th>He2</th>
                            <th>He3</th>
                            <th>He4</th>
                            <th>He5</th>
                        </tr>
                        <tr :class="{'current-row': index+1 == currentRow}">
                            <!-- <td v-for="item in xpItems" :key="item" >{{ item }}</td> -->
                            <td :id="'lvl' + (index + 1)">{{ index + 1 }}</td>
                            <td class="percent-text" v-for="(item, key) in xpItems" :key="key" :class="getClassByValue(item, index)">{{ item }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
import ExperienceData from "../../data/experience";

export default {
  name: 'Experience',
  components: {},

  data()
  {
    return {
        searchLevel: '',
        items: ExperienceData,
        maxValues: [],
        currentRow: '',
        acts: [
            'No1',
            'No2',
            'No3',
            'No4',
            'No5',

            'Ni1',
            'Ni2',
            'Ni3',
            'Ni4',
            'Ni5',

            'He1',
            'He2',
            'He3',
            'He4',
            'He5'
        ]
    }
  },

  created()
  {
      document.getElementById('link-experience').classList.add('active');

      for(let items of this.items)
      {
          let max = items[0];

          for(let item of items)
          {
              if(max < item)
              {
                  max = item;
              }
          }

          this.maxValues.push(max);
      }

      let current = 67;
      let maxValue = this.maxValues[current-1];
      let maxIndex = this.items[current-1].indexOf(maxValue);

      console.log(this.acts[maxIndex]);
  },
  watch: {
        searchLevel(newVal)
        {            
            let intVal = parseInt(newVal);

            if(newVal !== '' && intVal >= 1 && intVal <= 99)
            {
                this.searchLevel=intVal;
                this.currentRow = this.searchLevel;
            }
            else
            {
                this.searchLevel = '';
            }
        }
  },
  methods: {
      scrollToLevel()
      {
        var element = document.getElementById('lvl' + this.searchLevel);

        if(element)
        {
            var top = element.offsetTop;
            window.scrollTo(0, top);
        }
      },
    getClassByValue(value, index)
    {
        if(value == this.maxValues[index])
        {
            return 'best';
        }

        switch (true)
        {
            case value < 20:
                return 'below-20';

            case value < 30:
                return 'below-30';

            case value < 40:
                return 'below-40';

            case value < 50:
                return 'below-50';
            
            case value < 60:
                return 'below-60';

            case value < 70:
                return 'below-70';
            
            case value < 80:
                return 'below-80';

            case value < 90:
                return 'below-90';
        
            default:
                return 'above-90'
        }
    }
  }
}

</script>

<style>
.current-row, table.xp-table > tbody > tr.current-row:hover
{
    font-size: 18px;
    font-weight: 900;
    border-top: solid 3px #fff;
    border-bottom: solid 3px #fff;
}

.percent-text
{
    color: black;
}

.best
{
    background-color: #36f !important;
}

.below-20
{
    background-color: #ff0000 !important;
}

.below-30
{
    background-color: #f30 !important;
}

.below-40
{
    background-color: #f60 !important;
}

.below-50
{
    background-color: #f90 !important;
}

.below-60
{
    background-color: #fc0 !important;
}

.below-70
{
    background-color: #ff0 !important;
}

.below-80
{
    background-color: #cfc !important;
}

.below-90
{
    background-color: #cff !important;
}

.above-90
{
    background-color: #0ff !important;
}

table.xp-table > tbody > tr:hover
{
    border-top: 1px solid;
    border-bottom: 1px;
}

table.xp-table > tbody > tr:hover
{
    border-bottom: 1px solid white;
}

</style>