<template>
<div class="row px-4">
    <div class="col-12">
        <h2>Breakpoints</h2>
    </div>
</div>

<div class="row row justify-content-md-center">
    <div class="col-lg-8 py-4 col-sm-12 text-block d-md-block d-sm-none">
        Breakpoints are the values at which spells, attacks, hit recovery and other animations become a frame faster or slower. It's essential to know the main breakpoints for your character's attack rate, and knowing all the rest is very useful to wisely plan your equipment.
        Different characters have different breakpoints. They are calculated according to faster block rate <a href="#fbr-table">table</a>, faster cast rate <a href="#fcr-table">table</a> and faster hit recovery <a href="#fhr-table">table</a>.
    </div>
</div>

<div class="row py-2">
    <div class="col-md-4 py-1 py-md-0">
        <select class="form-select" v-model="searchClass">
            <option selected value="">select class first</option>
            <option v-for="(profession, index) in classes" :key="index" :value="profession">{{ profession }}</option>
        </select>
    </div>
</div>
<div class="row py-2">
    <div class="col-md-4 py-1 py-md-0">
        <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="FBR bonus" v-model="searchFbr" :disabled="!searchClass">
        </div>

        <div class="card" v-if="currentFbrIndex">
            <div class="card-title">
                <b>FBR</b>
            </div>
            <div class="card-body bp-body">
                <div v-for="(data, index) in currentFbrIndex" :key="index" class="bp-description">
                    <b>{{ index }}</b> at {{ data.currentFrames }} frames. 
                    <span v-if="data.needPoints !== 'N/A'">Need {{ data.needPoints }} points for {{ data.nextFrames }} frames.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-4 py-1 py-md-0">
        <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="FCR bonus" v-model="searchFcr" :disabled="!searchClass">
        </div>

        <div class="card" v-if="currentFcrIndex">
            <div class="card-title">
                <b>FCR</b>
            </div>
            <div class="card-body bp-body">
                <div v-for="(data, index) in currentFcrIndex" :key="index" class="bp-description">
                    <b>{{ index }}</b> at {{ data.currentFrames }} frames. 
                    <span v-if="data.needPoints !== 'N/A'">Need {{ data.needPoints }} points for {{ data.nextFrames }} frames.</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 py-1 py-md-0">
        <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="FHR bonus" v-model="searchFhr" :disabled="!searchClass">
        </div>

        <div class="card" v-if="currentFhrIndex">
            <div class="card-title">
                <b>FHR</b>
            </div>
            <div class="card-body bp-body">
                <div v-for="(data, index) in currentFhrIndex" :key="index" class="bp-description">
                    <b>{{ index }}</b> at {{ data.currentFrames }} frames. 
                    <span v-if="data.needPoints !== 'N/A'">Need {{ data.needPoints }} points for {{ data.nextFrames }} frames.</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" id="fbr-table">
    <div class="col-12 py-4">
        <h3>Faster block rate (FBR)</h3>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="table-responsive">
            <table class="table table-dark table-bordered" style="vertical-align: middle;">
                <thead>
                    <tr>
                        <th rowspan="2">Character</th>
                        <th rowspan="2">Weapon / Skill / Form</th>
                        <th :colspan="fbrFrames.length">Blocking frames</th>
                    </tr>
                    <tr>
                        <th scope="col" v-for="(frame, index) in fbrFrames" :key="index">{{ frame }}</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(character, charIndex) in getFbrData()" :key="charIndex">
                        <tr v-for="(props, specIndex) in character" :key="specIndex">
                            <td :rowspan="Object.keys(character).length" v-if="specIndex == Object.keys(character)[0]">{{ charIndex }}</td>

                            <td>{{ specIndex }}</td>

                            <td v-for="(prop, index) in props" :key="index" :class="{'current-bonus':currentBonus(charIndex, specIndex, index, currentFbrIndex) }">{{ prop }}</td>
                        </tr>
                    </template>
                </tbody>
                <thead v-if="!searchClass">
                    <tr>
                        <th rowspan="2">Character</th>
                        <th rowspan="2">Weapon / Skill / Form</th>
                        <th scope="col" v-for="(frame, index) in fbrFrames" :key="index">{{ frame }}</th>
                    </tr>
                    <tr>
                        <th :colspan="fbrFrames.length">Blocking frames</th>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</div>

<div class="row" id="fcr-table">
    <div class="col-12 py-4">
        <h3>Faster cast rate (FCR)</h3>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="table-responsive">
            <table class="table table-dark table-bordered" style="vertical-align: middle;">
                <thead>
                    <tr>
                        <th rowspan="2">Character</th>
                        <th rowspan="2">Weapon / Skill / Form</th>
                        <th :colspan="fcrFrames.length">Casting frames</th>
                    </tr>
                    <tr>
                        <th v-for="(frame, index) in fcrFrames" :key="index">{{ frame }}</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(character, charIndex) in getFcrData()" :key="charIndex">
                        <tr v-for="(props, specIndex) in character" :key="specIndex">
                            <td :rowspan="Object.keys(character).length" v-if="specIndex == Object.keys(character)[0]">{{ charIndex }}</td>

                            <td>{{ specIndex }}</td>

                            <td v-for="(prop, index) in props" :key="index" :class="{'current-bonus':currentBonus(charIndex, specIndex, index, currentFcrIndex) }">{{ prop }}</td>
                        </tr>
                    </template>
                </tbody>
                <thead v-if="!searchClass">
                    <tr>
                        <th rowspan="2">Character</th>
                        <th rowspan="2">Weapon / Skill / Form</th>
                        <th v-for="(frame, index) in fcrFrames" :key="index">{{ frame }}</th>
                        
                    </tr>
                    <tr>
                        <th :colspan="fcrFrames.length">Casting frames</th>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</div>

<div class="row" id="fhr-table">
    <div class="col-12 py-4">
        <h3>Faster hit recovery (FHR)</h3>
    </div>
</div>
<div class="row">
    <div class="col-12 ">
        <div class="table-responsive">
            <table class="table table-dark table-bordered" style="vertical-align: middle;">
                <thead>
                    <tr>
                        <th rowspan="2">Character</th>
                        <th rowspan="2">Weapon / Skill / Form</th>
                        <th :colspan="fhrFrames.length">Hit recovering frames</th>
                    </tr>
                    <tr>
                        <th v-for="(frame, index) in fhrFrames" :key="index">{{ frame }}</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(character, charIndex) in getFhrData()" :key="charIndex">
                        <tr v-for="(props, specIndex) in character" :key="specIndex">
                            <td :rowspan="Object.keys(character).length" v-if="specIndex == Object.keys(character)[0]">{{ charIndex }}</td>

                            <td>{{ specIndex }}</td>

                            <td v-for="(prop, index) in props" :key="index" :class="{'current-bonus':currentBonus(charIndex, specIndex, index, currentFhrIndex) }">{{ prop }}</td>
                        </tr>
                    </template>
                </tbody>
                <thead v-if="!searchClass">
                    <tr>
                        <th rowspan="2">Character</th>
                        <th rowspan="2">Weapon / Skill / Form</th>
                        <th v-for="(frame, index) in fhrFrames" :key="index">{{ frame }}</th>
                        
                    </tr>
                    <tr>
                        <th :colspan="fhrFrames.length">Hit recovering frames</th>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</div>

</template>

<script>

const CLASS_AMAZON = 'Amazon';
const CLASS_ASSASIN = 'Assasin';
const CLASS_BARBARIAN = 'Barbarian';
const CLASS_DRUID = 'Druid';
const CLASS_NECROMANCER = 'Necromancer';
const CLASS_PALADIN = 'Paladin';
const CLASS_SORCERESS = 'Sorceress';

const MERC_ACT_1 = 'Merc Act 1';
const MERC_ACT_2 = 'Merc Act 2';
const MERC_ACT_3 = 'Merc Act 3';
const MERC_ACT_5 = 'Merc Act 5';

export default {
  name: 'RuneList',
  components: {},

  data()
  {
    return {
        searchClass: '',
        
        currentFbrIndex: undefined,
        fbrData: undefined,
        searchFbr: '',
        fbrFrames: [17, 16, 15, 14, 13, 12 ,11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
        fbr: {
            [CLASS_AMAZON]: {
                "1 hand swinging weapons": [0, 4, 6, 11, 15, 23, 29, 40, 56, 80, 120, 200, 480, null, null, null, null],
                "other weapons": [null, null, null, null, null, null, null, null, null, null, null, null, 0, 13, 32, 86, 600]
            },
            [CLASS_ASSASIN]: {
                'all': [null, null, null, null, null, null, null, null, null, null, null, null, 0, 13, 32, 86, 600]
            },
            [CLASS_BARBARIAN]: {
                'all': [null, null, null, null, null, null, null, null, null, null, 0, 9, 20, 42, 86, 280, null]
            },
            [CLASS_DRUID]: {
                "Human form": [null, null, null, null, null, null, 0, 6, 13, 20, 32, 52, 86, 174, 600, null, null],
                "Bear form": [null, null, null, null, null, null, 0, 7, 15, 27, 40, 65, 109, 223, null, null, null],
                "Wolf form": [null, null, null, null, null, null, null, null, 0, 7, 15, 27, 48, 86, 200, null, null],
            },
            [CLASS_NECROMANCER]: {
                "all": [null, null, null, null, null, null, 0, 6, 13, 20, 32, 52, 86, 174, 600, null, null],
            },
            [CLASS_PALADIN]: {
                "normal": [null, null, null, null, null, null, null, null, null, null, null, null, 0, 13, 32, 86, 600],
                "Holy shield": [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 0, 86],
            },
            [CLASS_SORCERESS]: {
                "all": [null, null, null, null, null, null, null, null, 0, 7, 15, 27, 48, 86, 200, null, null],
            }
        },

        currentFcrIndex: undefined,
        fcrData: undefined,
        searchFcr: '',
        fcrFrames: [23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7],
        fcr: {
            [CLASS_AMAZON]: {
                "all": [null, null, null, null, 0, 7, 14, 22, 32, 48, 68, 99, 152, null, null, null, null],
            },
            [CLASS_ASSASIN]: {
                "all": [null, null, null, null, null, null, null, 0, 8, 16, 27, 42, 65, 102, 174, null, null],
            },
            [CLASS_BARBARIAN]: {
                "all": [null, null, null, null, null, null, null, null, null, null, 0, 9, 20, 37, 63, 105, 200],
            },
            [CLASS_DRUID]: {
                "Human": [null, null, null, null, null, 0, 4, 10, 19, 30, 46, 68, 99, 163, null, null, null],
                "Bear form": [null, null, null, null, null, null, null, 0, 7, 15, 26, 40, 63, 99, 163, null, null],
                "Wolf form": [null, null, null, null, null, null, null, 0, 6, 14, 26, 40, 60, 95, 157, null, null],
            },
            [CLASS_NECROMANCER]: {
                "Human": [null, null, null, null, null, null, null, null, 0, 9, 18, 30, 48, 75, 125, null, null],
                "Vampire": [0, 6, 11, 18, 24, 35, 48, 65, 86, 120, 180, null, null, null, null, null, null],
            },
            [CLASS_PALADIN]: {
                "all": [null, null, null, null, null, null, null, null, 0, 9, 18, 30, 48, 75, 125, null, null],
            },
            [CLASS_SORCERESS]: {
                "Lightning / Chain lightning": [null, null, null, null, 0, 7, 15, 23, 35, 52, 78, 117, 194, null, null, null, null],
                "other spells": [null, null, null, null, null, null, null, null, null, null, 0, 9, 20, 37, 63, 105, 200],
            },
            [MERC_ACT_3]: {
                "all": [null, null, null, null, null, null, 0, 8, 15, 26, 39, 58, 86, 138, null, null, null],
            }
        },

        currentFhrIndex: undefined,
        searchFhr: '',
        fhrFrames: [17, 16, 15, 14, 13, 12 ,11, 10, 9, 8, 7, 6, 5, 4, 3, 2],
        fhr: {
            [CLASS_AMAZON]: {
                "all": [null, null, null, null, null, null, 0, 6, 13, 20, 32, 52, 86, 174, 600, null]
            },
            [CLASS_ASSASIN]: {
                "all": [null, null, null, null, null, null, null, null, 0, 7, 15, 27, 48, 86, 200, null],
            },
            [CLASS_BARBARIAN]: {
                "all": [null, null, null, null, null, null, null, null, 0, 7, 15, 27, 48, 86, 200, null]
            },
            [CLASS_DRUID]: {
                'Human 1H swinging weapon': [null, null, null, 0, 3, 7, 13, 19, 29, 42, 63, 99, 174, 456, null, null],
                'Human other weapons': [null, null, null, null, 0, 5, 10, 16, 26, 39, 56, 86, 152, 377, null, null],
                'Bear form': [null, null, null, null, 0, 5, 10, 16, 24, 37, 54, 86, 152, 360, null, null],
                'Wolf form': [null, null, null, null, null, null, null, null, null, null, 0, 9, 20, 42, 86, 280],
            },
            [CLASS_NECROMANCER]: {
                "Human": [null, null, null, null, 0, 5, 10, 16, 26, 39, 56, 86, 152, 377, null, null],
                "Vampire": [null, null, 0, 2, 6, 10, 16, 24, 34, 48, 72, 117, null, null, null, null],
            },
            [CLASS_PALADIN]: {
                "Spears and staves": [null, null, null, null, 0, 3, 7, 13, 20, 32, 48, 75, 129, 280, null, null],
                "other weapons": [null, null, null, null, null, null, null, null, 0, 7, 15, 27, 48, 86, 200, null]
            },
            [CLASS_SORCERESS]: {
                'all': [null, null, 0, 5, 9, 14, 20, 30, 42, 60, 86, 142, 280, null, null, null],
            },
            [MERC_ACT_1]: {
                "all": [null, null, null, null, null, null, 0, 6, 13, 20, 32, 52, 86, 174, 600, null]
            },
            [MERC_ACT_2]: {
                'all': [null, null, 0, 5, 9, 14, 20, 30, 42, 60, 86, 142, 280, null, null, null],
            },
            [MERC_ACT_3]: {
                'all': [0, 5, 8, 13, 18, 24, 32, 46, 63, 86, 133, 232, 600, null, null, null],
            },
            [MERC_ACT_5]: {
                "all": [null, null, null, null, null, null, null, null, 0, 7, 15, 27, 48, 86, 200, null]
            },
        },
        // ias: {
        //     CLASS_AMAZON: {

        //     },
        //     CLASS_ASSASIN: {
                
        //     },
        //     CLASS_BARBARIAN: {
                
        //     },
        //     CLASS_NECROMANCER: {
                
        //     },
        //     CLASS_PALADIN: {
                
        //     },
        //     CLASS_SORCERESS: {
                
        //     }
        // },
        classes: [
            CLASS_AMAZON,
            CLASS_ASSASIN,
            CLASS_BARBARIAN,
            CLASS_DRUID,
            CLASS_NECROMANCER,
            CLASS_PALADIN,
            CLASS_SORCERESS
        ]
    }
  },

  created()
  {
      document.getElementById('link-breakpoints').classList.add('active');
  },
  methods: {
      scrollTo(elementId)
      {
          window.scrollTo(document.getElementById(elementId));
      },
      currentBonus(chClass, chSpec, index, currentIndex)
      {
          return chClass == this.searchClass 
            && currentIndex  
            && currentIndex[chSpec]
            && currentIndex[chSpec] == index;
      },

      getFbrData()
      {
          return this.searchClass && this.fbr[this.searchClass]
                ? {[this.searchClass]: this.fbr[this.searchClass]}
                : this.fbr;
      },

      getFcrData()
      {
          return this.searchClass && this.fcr[this.searchClass]
                ? {[this.searchClass]: this.fcr[this.searchClass]}
                : this.fcr;
      },

      getFhrData()
      {
          return this.searchClass && this.fhr[this.searchClass]
                ? {[this.searchClass]: this.fhr[this.searchClass]}
                : this.fhr;
      },

      calculateBreakpoints()
      {
          this.currentFbrIndex = this.calculate(this.fbrFrames, this.fbr, this.searchFbr);
          this.currentFcrIndex = this.calculate(this.fcrFrames, this.fcr, this.searchFcr);
          this.currentFhrIndex = this.calculate(this.fhrFrames, this.fhr, this.searchFhr);

          //this.setFbrData();

        //   if(!this.searchClass || !(parseInt(this.searchFbr) >= 0))
        //   {
        //       return this.currentFbrIndex=undefined;
        //   }

        //   let chClass = this.fbr[this.searchClass];
        //   let ret = {}

        //   for(let key in chClass)
        //   {
        //     let index = getBonusIndex(this.searchFbr, chClass[key]);
        //     console.log("fbr for " + key + " is: " + this.fbrFrames[index]);
        //     ret[key] = index;
        //   }

        //   this.currentFbrIndex = ret;
      },

      calculate(frames, data, value)
      {
          if(!this.searchClass || !(parseInt(value) >= 0))
          {
              return undefined;
          }

          let chClass = data[this.searchClass];
          let ret = {}
          let test = {};

          for(let key in chClass)
          {
              let index = parseInt(getBonusIndex(value, chClass[key]));
              let next = 'N/A';
              let need = 'N/A';

              const classSpec = data[this.searchClass][key];

              if(index < frames.length && classSpec[index + 1] !== null)
              {
                  need = classSpec[index + 1] - value;
                  next = this.fbrFrames[index + 1];
              }
              
              test[key] = {
                currentPoints: value,
                currentFrames: this.fbrFrames[index],
                nextFrames: next,
                needPoints: need
              }

            ret[key] = index;
          }

          return test
      },

//deprecated
      setFbrData()
      {
          if(!this.currentFbrIndex)
          {
            this.fbrData = undefined;
          }

          let data = {};

          for(let spec in this.currentFbrIndex)
          {
              let currentIndex=parseInt(this.currentFbrIndex[spec]);
              let next = 'N/A';
              let need = 'N/A';

              const classSpec = this.fbr[this.searchClass][spec];

              if(currentIndex < this.fbrFrames.length && classSpec[currentIndex + 1] !== null)
              {
                  need = classSpec[currentIndex + 1] - this.searchFbr;
                  next = this.fbrFrames[currentIndex + 1];
              }
              
              data[spec] = {
                currentFrames: this.fbrFrames[currentIndex],
                nextFrames: next,
                needPoints: need
              }
          }

          this.fbrData = data;
      },

      getSearchFieldValue(newVal)
      {
        let intVal = parseInt(newVal);

        return (newVal !== '' && intVal >= 0 && intVal < 600)
            ? intVal
            : this.searchFbr = '';
      },
  },
  watch: {
        searchClass()
        {
            this.calculateBreakpoints();
        },

        searchFbr(newVal)
        {            
            this.searchFbr = this.getSearchFieldValue(newVal);
            this.calculateBreakpoints();
        },

        searchFcr(newVal)
        {            
            this.searchFcr = this.getSearchFieldValue(newVal);
            this.calculateBreakpoints();
        },

        searchFhr(newVal)
        {            
            this.searchFhr = this.getSearchFieldValue(newVal);
            this.calculateBreakpoints();
        }
    }
}

function getBonusIndex(currentValue, bonuses)
{
    let prevValue = null;
    let index = 0;

    for(let bonusIndex in bonuses)
    {
        if((bonuses[bonusIndex] && bonuses[bonusIndex] > currentValue)
        || (prevValue != null && bonuses[bonusIndex] === null)//reached max values
        )
        {
            return index;
        }

        prevValue = bonuses[bonusIndex];
        index = bonusIndex;
    }

    return index;
}

</script>

<style>
.bp-description
{
    line-height: 18px;
}

.bp-body
{
    text-align: justify;
}

.current-bonus
{
    background-color: rgb(160, 53, 0) !important;
}

</style>