<template>
    <div class="item-detail">
        <div class="item-header">
            <!-- <div class="item-header-container header-btn">
                <span @click="add()">
                    <i class="bi bi-plus"></i>
                </span>
            </div> -->
            <div class="item-header-container item-name">
                <span>{{ item.name }}</span>
            </div>
            <!-- <div class="item-header-container header-btn">
                <span @click="remove()" class="header-btn">
                    <i class="bi bi-dash"></i>
                </span>
            </div> -->
        </div>
        <div class="">
            <div class="item-properties">
                <div :key="property" v-for="property in item.properties">{{ property }}</div>
            </div>
            <div v-if="item.ingredients" class="item-ingredients">
                <div style="text-decoration: underline; padding-bottom: 12px">Created from:</div>
                {{ item.ingredients.map(item => item.quantity + 'x ' + item.item.name).join(", ") }}
            </div>
            <div v-if="item.level" class="item-ingredients">
                <div style=" padding-bottom: 12px">Level: {{ item.level }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ItemDetail',
    props: {
        item: Object,
    }
}
</script>

<style scoped>
    .item-detail{
        white-space: break-spaces;
        width: 100%;
        padding: 12px 0px;
        margin: 12px 0px;
    }

    .item-header{
        font-weight: bold;
        padding: 24px 0px 6px;
        text-align: center;
        /* border-bottom: solid rgba(0,0,0,.125); */
        padding-bottom: 16px;;
    }

    .header-btn
    {
        display: inline-block;
        width: 20%;
        cursor: pointer;
    }

    .item-ingredients
    {
        padding: 24px 0px 6px 0px;
        font-style: italic;
    }

    .item-name
    {
        width: 60%; 
        display: inline-block;
    }

    .item-header-container
    {
        display: inline-block;
    }

</style>