<template>
    <div class="row item-container">
        <div class="col-xxl-7 col-xl-6 col-lg-12 col-md-12 col-sm-12">{{ item.item.name }}</div>
        <div class="col-xxl-5 col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div class="input-group input-group-sm">
                <button class="btn btn-primary" type="button" id="button-addon1" @click="add()">+</button>
                <input type="number" class="form-control" style="text-align: center;" v-model="item.quantity" @change="change()">
                <button class="btn btn-primary" type="button" id="button-addon1" @click="remove()">-</button>
                &nbsp;
                &nbsp;
                <button class="btn btn-sm btn-danger" type="button" @click="clear()">x</button>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Item',
    props: {
        item: Object,
        inventory: Object
    },
    data()
    {
        return {
            count: this.item.quantity
        }
    },
    methods: {
        add()
        {
            this.inventory.add(this.item.item, 1);
            // this.item.quantity == 99 
            //     ? this.item.quantity = 1
            //     : this.item.quantity += 1;

            // this.inventory.inventoryHasChanged();
        },

        remove()
        {
            this.item.quantity == 1 
                ? this.clear()
                : this.item.quantity -= 1;

            this.inventory.inventoryHasChanged();
        },

        clear()
        {
            //this.inventory.add(this.item.item, -this.item.quantity);
            this.inventory.clear(this.item.item);
        },
        change()
        {
            if(!this.item.quantity || this.item.quantity < 1)
            {
                this.inventory.clear(this.item.item);
            }
            else if(this.item.quantity > 99)
            {
                this.item.quantity = 1;
            }

            this.inventory.inventoryHasChanged();
        }
    },
}
</script>
<style scoped>
    .item-container
    {
        /* width: 100%; */
        padding: 12px 0px;
    }

    .item-name
    {
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }

    .item-count
    {
        display: block;
        max-width: 30px;
        float: right;
    }

    .item-clear
    {
        display: block;
        float: right;
    }
</style>
