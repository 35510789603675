const experience = [
    [67, 8, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [77, 9, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [85, 11, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [91, 13, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [96, 16, 6, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [98, 22, 7, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [98, 29, 10, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [96, 39, 12, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [93, 48, 13, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [89, 58, 14, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [82, 67, 15, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [74, 77, 17, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [68, 83, 23, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [58, 89, 34, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [48, 92, 51, 8, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [38, 94, 68, 13, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [29, 94, 83, 23, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [20, 93, 91, 34, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [14, 92, 95, 47, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [10, 87, 95, 61, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [8, 82, 94, 72, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [6, 75, 92, 82, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [6, 65, 91, 89, 7, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [5, 55, 89, 95, 10, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [5, 44, 90, 92, 67, 64, 54, 49, 44, 42, 36, 32, 31, 30, 30],
    [5, 35, 89, 95, 70, 67, 56, 51, 46, 43, 37, 33, 32, 31, 32],
    [5, 27, 86, 97, 72, 70, 58, 53, 48, 45, 38, 35, 33, 32, 33],
    [5, 20, 78, 98, 75, 72, 61, 55, 49, 47, 40, 36, 35, 33, 34],
    [5, 14, 67, 99, 78, 75, 63, 57, 51, 48, 41, 37, 36, 35, 35],
    [5, 11, 52, 95, 80, 77, 65, 59, 53, 50, 43, 38, 37, 36, 37],
    [5, 8, 37, 90, 83, 80, 67, 61, 55, 52, 44, 40, 38, 37, 38],
    [5, 7, 24, 83, 86, 82, 69, 63, 56, 53, 45, 41, 40, 38, 39],
    [5, 6, 15, 69, 88, 85, 71, 65, 58, 55, 47, 42, 41, 39, 40],
    [5, 5, 10, 55, 90, 88, 73, 67, 60, 57, 48, 44, 42, 41, 41],
    [5, 5, 8, 47, 92, 90, 76, 69, 62, 58, 50, 45, 43, 42, 43],
    [5, 5, 8, 34, 94, 93, 78, 71, 64, 60, 51, 46, 44, 43, 44],
    [5, 5, 8, 22, 94, 95, 80, 73, 65, 62, 53, 47, 46, 44, 45],
    [5, 5, 8, 17, 94, 97, 82, 75, 67, 63, 54, 49, 47, 45, 46],
    [5, 5, 8, 12, 92, 98, 84, 77, 69, 65, 55, 50, 48, 47, 47],
    [5, 5, 8, 6, 88, 99, 86, 79, 71, 67, 57, 51, 49, 48, 49],
    [5, 5, 8, 6, 82, 99, 89, 81, 72, 68, 58, 52, 51, 49, 50],
    [5, 5, 8, 5, 75, 97, 91, 83, 74, 70, 60, 54, 52, 50, 51],
    [5, 5, 8, 5, 67, 93, 93, 85, 76, 72, 61, 55, 53, 51, 52],
    [5, 5, 8, 5, 58, 84, 94, 87, 78, 73, 63, 56, 54, 53, 54],
    [5, 5, 8, 5, 50, 72, 96, 88, 79, 75, 64, 58, 56, 54, 55],
    [5, 5, 7, 5, 42, 56, 97, 90, 81, 77, 65, 59, 57, 55, 56],
    [5, 5, 7, 5, 34, 42, 97, 92, 83, 78, 67, 60, 58, 56, 57],
    [5, 5, 6, 5, 26, 29, 97, 94, 85, 80, 68, 61, 59, 57, 58],
    [5, 5, 6, 5, 20, 13, 94, 95, 86, 82, 70, 63, 60, 59, 60],
    [5, 5, 5, 5, 14, 13, 88, 95, 88, 83, 71, 64, 62, 60, 61],
    [5, 5, 5, 5, 11, 10, 79, 95, 90, 85, 73, 65, 63, 61, 62],
    [5, 5, 5, 5, 9, 7, 69, 94, 92, 87, 74, 67, 64, 62, 63],
    [5, 5, 5, 5, 8, 6, 58, 93, 94, 88, 75, 68, 65, 63, 65],
    [5, 5, 5, 5, 8, 5, 47, 91, 95, 90, 77, 69, 67, 65, 66],
    [5, 5, 5, 5, 8, 5, 37, 87, 97, 91, 78, 70, 68, 66, 67],
    [5, 5, 5, 5, 9, 5, 29, 80, 99, 92, 80, 72, 69, 67, 68],
    [5, 5, 5, 5, 9, 5, 22, 71, 100, 93, 81, 73, 70, 68, 69],
    [5, 5, 5, 5, 9, 5, 16, 61, 100, 95, 82, 74, 72, 69, 71],
    [5, 5, 5, 5, 9, 5, 13, 48, 100, 95, 84, 76, 73, 70, 72],
    [5, 5, 5, 5, 9, 5, 10, 37, 100, 95, 85, 77, 74, 72, 73],
    [5, 5, 5, 5, 8, 5, 9, 27, 99, 94, 87, 78, 75, 73, 74],
    [5, 5, 5, 5, 8, 5, 8, 19, 93, 92, 88, 79, 77, 74, 76],
    [5, 5, 5, 5, 8, 5, 8, 12, 75, 89, 90, 81, 78, 75, 77],
    [5, 5, 5, 5, 7, 5, 7, 8, 56, 86, 91, 82, 79, 76, 78],
    [5, 5, 5, 5, 7, 5, 7, 6, 37, 80, 92, 83, 80, 78, 79],
    [5, 5, 5, 5, 6, 5, 6, 5, 19, 74, 94, 84, 81, 79, 80],
    [5, 5, 5, 5, 6, 5, 6, 5, 6, 63, 95, 86, 83, 80, 82],
    [5, 5, 5, 5, 5, 5, 5, 5, 2, 52, 97, 87, 84, 81, 83],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 41, 98, 88, 85, 82, 84],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 30, 98, 90, 86, 84, 85],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 21, 99, 91, 88, 85, 86],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 15, 99, 93, 89, 86, 88],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 11, 98, 94, 90, 87, 89],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 9, 92, 95, 91, 88, 90],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 7, 82, 96, 93, 90, 91],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 70, 97, 94, 91, 92],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 56, 98, 95, 92, 93],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 42, 99, 96, 93, 93],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 31, 99, 97, 94, 94],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 24, 99, 98, 96, 94],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 18, 96, 99, 97, 95],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 15, 91, 99, 98, 96],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 13, 83, 99, 99, 96],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 12, 71, 96, 100, 96],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 11, 58, 91, 100, 95],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 10, 45, 83, 100, 93],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 9, 33, 71, 100, 87],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 9, 23, 58, 95, 78],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 8, 16, 45, 88, 66],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 8, 11, 33, 76, 52],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 7, 7, 23, 57, 36],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 6, 16, 38, 24],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 6, 11, 24, 15],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 7, 12, 10],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 5, 7],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 5, 7],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 7],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 7],
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 7]

];

export default experience;