<template>
    <div class="item-detail card">
        <div class="item-header card-title">
            <span style="width: 60%; display: inline-block;">{{ item.name }}</span>
            <!-- <span v-if="quantity">({{ quantity }}x)</span> -->
        </div>
        <div class="card-body">
            <div class="item-properties">
                <div :key="property" v-for="property in item.properties">{{ property }}</div>
            </div>
            <div v-if="item.ingredients" class="item-ingredients">
                <!-- <span :key="rune" v-for="rune in item.runes"> -->
                <span :key="key" v-for="(rune, key) in item.runes">                    
                    <img :src="rune.imgSrc" height="30" :alt="rune.name" @mouseover="mouseover(rune)" @mouseleave="mouseover(rune, false)" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="$emit('displayItem', rune)" style="cursor: pointer">
                    <span :class="{'is-missing': missing.indexOf(rune.name) >= 0}">{{ rune.name }}</span>&nbsp;
                    <span v-if="key != item.runes.length - 1">+</span>&nbsp;
                </span>
                <!-- {{ item.runes.map(item => item.name).join(" + ") }} -->
            </div>
            <div v-if="item.applicable" class="item-ingredients">            
                {{ item.applicable.join(", ") }}
            </div>
            <div v-if="item.patch" style="color: #212529;">(patch {{ item.patch }})</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RunewordDetail',
    props: {
        item: Object,
        missing: Array,
        quantity: Number
    },
    methods: {
        mouseover(rune, active = true)
        {
            this.$emit('onRuneOver', active ? rune.name : '');
        },
    },
    emits: ['onRuneOver']
}
</script>

<style scoped>
    .item-detail{
        white-space: break-spaces;
        width: 100%;
        padding: 12px 0px;
        margin: 12px 0px;
    }

    .item-header{
        font-weight: bold;
        padding: 6px 0px;
        text-align: center;
        border-bottom: solid rgba(0,0,0,.125);
        padding-bottom: 16px;
        color: darkgoldenrod;
    }

    .item-properties
    {
        color: darkblue;
        padding-bottom: 8px;
    }

    .header-btn
    {
        display: inline-block;
        width: 20%;
        cursor: pointer;
    }

    .item-ingredients
    {
        padding: 6px 0px;
        font-style: italic;
        color: #212529;
    }

    .is-missing
    {
        color: darkred;
    }
</style>