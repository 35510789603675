<template>
<div class="rune-container" style="max-width: 62px; text-align: center; padding: 6px;" :class="{ 'in-inventory': count, 'highlight': item.name == highlight}">
    <div class="rune-name" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="$emit('displayItem', item)" style="line-height: 18px">
    {{ item.name }}
    </div>
    <div class="rune-img">
    <img :src="item.imgSrc" :alt="item.name" width="48" @click="add()" @contextmenu="remove($event)" @change="change">
    </div>
    <div class="count-container">
    <input type="text" class="form-control form-control-sm" v-model="count">
    </div>
</div>
<br>
</template>

<script>
export default {
    name: 'ItemDetail',
    data(){
        return {
            count: 0
        }
    },
    emits: ['displayItem'],
    props: {
        item: Object,
        inventory: Object,
        highlight: String
    },
    created()
    {
        let inventoryItem = this.inventory.items.find(item => item.item.name == this.item.name);

        this.count = inventoryItem ? inventoryItem.quantity : 0;
    },
    methods: {
        add()
        {
            this.count += 1;
            this.inventory.add(this.item);
        },
        remove(ev)
        {
            ev.preventDefault();

            if(this.count > 0)
            {
                this.count -= 1;
                this.inventory.add(this.item, -1);
            }
        }
    },
    watch: {
        count(newVal)
        {
            let intVal = parseInt(newVal);

            if(intVal && intVal < 99)
            {
                this.count = intVal;
                return this.inventory.set(this.item, intVal)
            }
            
            this.inventory.clear(this.item);
            this.count = 0;
        }
    }
}
</script>

<style scoped>
    .item-detail{
        white-space: break-spaces;
        width: 100%;
        padding: 12px 0px;
        margin: 12px 0px;
    }

    .item-header{
        font-weight: bold;
        padding: 6px 0px;
        text-align: center;
        border-bottom: solid rgba(0,0,0,.125);
        padding-bottom: 16px;;
    }

    .header-btn
    {
        display: inline-block;
        width: 20%;
        cursor: pointer;
    }

    .item-ingredients
    {
        padding: 6px 0px;
        font-style: italic;
    }

    .item-name
    {
        width: 60%; 
        display: inline-block;
    }

    .item-header-container
    {
        display: inline-block;
    }

    .count-container > .form-control
    {
        text-align: center;
    }

    .in-inventory
    {
        background-color: rgba(0, 100, 0, 0.5);
    }

    .rune-img
    {
        padding: 6px 0px;
        cursor: pointer;
    }

    .rune-name
    {
        font-size: 16px;
        font-weight: 600;
    }

    .highlight
    {
        background-color: burlywood;
    }

</style>